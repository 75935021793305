import React from "react";

import styles from "./ProgressBar.module.scss";

const ProgressBar = ({ icon, title, level = 50 }) => {
    // width animated by transition in _progressbar.scss
    const style = {
        width: level + "%",
    };

    // TODO: rewrite and remove scss file styles
    return (
        <>
            <div
                className={styles.progressbar__container}
                style={{ width: "100%" }}
            >
                <div className={styles.progressbar__heading}>
                    {icon && <icon className={styles.progressbar__icon} />}
                    <span className={styles.progressbar__title}>{title}</span>
                </div>
                <div className={styles.progressbar__bar} style={style}></div>
                <div>
                    <span
                        className={styles.progressbar__value}
                    >{`${level}%`}</span>
                </div>
            </div>
        </>
    );
};

export default ProgressBar;
