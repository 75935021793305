// Default values for Redux store initialisation

// used by reducers/user.js
export const userDefaultState = {
    isLoggedIn: false,
    loginTimestamp: undefined,
    language: "de-DE", // either de-DE or en-US
    colorMode: "light", // either 'light' or 'dark'
    profile: null, // user attributes from WP account
    acceptedCookies: false,
    roles: [],
    error: null,
};

// used by reducers/wpContent.js
export const wpContentDefaultState = {
    configs: null,
    pages: null,
    media: null, // holds media array and meta data object
    messages: null,
    error: null,
};

// used by reducers/loading.js
export const loadingDefaultState = {
    isLoading: true,
};

// used by reducers/menu.js
export const visibilityDefaultState = {
    contactForm: false,
};
