import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";
import Controls from "../../../mui/controls/Controls";

import { toast } from "react-toastify";

import { startLogoutUser } from "../../../thunks/user";
import { getUserProfile } from "../../../selectors/user";

const Logout = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    // Get profile of authenticated user for good-bye message
    const userProfile = useSelector((state) => getUserProfile(state));
    // enable i18n translations
    const { t } = useTranslation();

    const notifySuccess = (name) =>
        toast.success(`${t("auth.logout_success")}, ${name}!`);
    const notifyFailure = () => toast.error(t("auth.logout_failure"));

    const handleLogout = () => {
        dispatch(startLogoutUser())
            .then(() => {
                notifySuccess(userProfile.firstName);
                history.push("/");
            })
            .catch(() => notifyFailure());
    };

    return (
        <Grid container>
            {/* <Grid item xs={12} textAlign="center">
                <Typography component="div" variant="h5" textAlign="center">
                    Leave Family & Friends
                </Typography>
            </Grid> */}
            <Grid item xs={12} textAlign="center">
                <Controls.Button
                    {...props}
                    color="accent"
                    onClick={handleLogout}
                    variant="outlined"
                >
                    <Typography variant="h6"> Logout</Typography>
                </Controls.Button>
            </Grid>
        </Grid>
    );
};

export default Logout;
