import React from "react";
import { styled } from "@mui/material/styles";
import { IconButton, Button } from "@mui/material";

// Navigation Links with Icons in Appbar/Toolbar
export const NavItemIconButton = styled(
    React.forwardRef((props, ref) => (
        <IconButton {...props} ref={ref} color="secondary" />
    ))
)(({ theme }) => ({
    marginLeft: theme.spacing(3),
    transition: `all ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
    "&:hover": {
        boxShadow: theme.shadows[5],
    },
    "&.active": {
        borderBottom: `3px solid ${theme.palette.accent.main}`,
    },
    "& svg": {
        height: "2rem",
        width: "2rem",
    },
}));

// Settings buttons for theme and language
export const SettingButton = styled(
    ({ title, icon: Icon, onClick, ...rest }) => (
        <Button
            {...rest}
            variant="text"
            color="secondary"
            onClick={onClick}
            startIcon={<Icon />}
        >
            {title}
        </Button>
    )
)(({ theme }) => ({
    fontSize: "0.6rem",
    justifyContent: "flex-start",
    ".MuiButton-startIcon": {
        margin: 0,
        marginRight: theme.spacing(1),
    },
}));
