import React from "react";

const Profile = ({ photo, name, roles }) => {
    return (
        <div className="profile-container">
            <figure className="bio">
                <img
                    src={photo.sizes.medium}
                    alt="Steffen Fidelius"
                    style={{ width: "100%" }}
                />
            </figure>
            <div className="name">{name}</div>
            <div className="tagline">{roles}</div>
        </div>
    );
};

export default Profile;
