import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

// Container around App content
export const Page = styled((props) => <Box {...props} />)(({ theme }) => ({
    // height: "88vh",
    display: "flex",
    justifyContent: "center",
    margin: "auto 0",
    // Mobile
    [theme.breakpoints.down("sm")]: {
        margin: 0,
        paddingTop: "2rem",
    },
    // Desktop
    [theme.breakpoints.up("sm")]: {
        margin: 0,
        paddingTop: "5rem",
    },
}));
