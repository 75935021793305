import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import parse from "html-react-parser"; // Rendering the HTML string, will be vulnerable to cross-site scripting attacks (XSS).
import moment from "moment";

import { Icons } from "../../mui/icons";

import { getPageById } from "../../selectors/wpContent";
import { getUserLanguage } from "../../selectors/user";

import { Grid, useTheme } from "@mui/material";
import { PageTitle, Text } from "../../mui/typography/Typography";
import { PortfolioSingleMetaChip } from "../../mui/display";
import { useBreadcrumbs } from "../../hooks/useBreadcrumbs";

import noMedia from "../../assets/images/no-media.jpg";
import NotFoundPage from "../PageNotFound/PageNotFound";

// Render single page with a specific portfolio topic
const PortfolioSingle = ({ history }) => {
    // Get access to MUI theme for styling
    const theme = useTheme();
    // Get current user language
    const language = useSelector((state) => getUserLanguage(state));
    // Store language in an instance variable to handle user language changes
    const ref = useRef(language);

    // Custom hook to create Breadcrumbs
    const [breadcrumbs] = useBreadcrumbs();

    // Get page id from URL params
    let { id } = useParams();

    // Get page for id
    const page = useSelector((state) => getPageById(state, id));

    // Show 404 Page if id does not exist or user changes URL manually
    if (!page) {
        return <NotFoundPage />;
    }

    const { title, excerpt, featuredImage, content, updatedAt, acf } = page;

    // Redirect to portfolio homepage to update language of portfolio item pages
    if (ref.current !== language) {
        history.push("/portfolio");
        return null;
    }

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                {breadcrumbs}
            </Grid>
            <Grid item xs={12}>
                <PageTitle variant="h4">{title}</PageTitle>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "space-around" }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Grid item>
                        <PortfolioSingleMetaChip
                            icon={<Icons.AccessTime />}
                            label={`${acf.reading_time || 3} min`}
                        ></PortfolioSingleMetaChip>
                    </Grid>
                    <Grid item>
                        <PortfolioSingleMetaChip
                            icon={<Icons.Edit />}
                            label={moment(updatedAt).format("L")}
                        ></PortfolioSingleMetaChip>{" "}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Text
                    sx={{
                        borderLeft: `5px solid ${theme.palette.accent.main}`,
                        fontSize: "1.1rem",
                        fontWeight: theme.typography.fontWeightBold,
                        paddingLeft: "1rem",
                    }}
                >
                    {excerpt}
                </Text>
            </Grid>
            <Grid item xs={12}>
                <img
                    src={featuredImage ? featuredImage.large : noMedia}
                    alt="portfolio topic"
                    style={{ maxWidth: "100%" }}
                />
            </Grid>
            <Grid item xs={12}>
                <Text>{parse(content)}</Text>
            </Grid>
        </Grid>
    );
};

export default PortfolioSingle;
