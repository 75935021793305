import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
    getMediaLibraryItems,
    getMediaLibraryMeta,
} from "../../../selectors/wpContent";

import { Grid, Skeleton } from "@mui/material";
import { Text } from "../../../mui/typography/Typography";

import ItemList from "./ItemList";
import ItemSingle from "./ItemSingle";
import ItemTagSelector from "./ItemTagSelector";

const Gallery = (props) => {
    // Store id of image to be shown in single view
    const [itemSingle, setItemSingle] = useState(null);
    const [tag, setTag] = useState("");

    // Get media library items after thunk function run
    const data = useSelector((state) => getMediaLibraryItems(state));

    // If state was updated by thunk function
    if (data) {
        // Get media items and meta data for gallery
        const { media, meta } = data;
        // Filter gallery items if user selected a specific tag
        const mediaFiltered = tag
            ? media.filter((item) => item.tags.includes(tag))
            : media;
        // Create array of customized item data
        const itemAll = mediaFiltered.map((item) => {
            const url = item.url.split("/").slice(0, -1).join("/");
            const medium = item.sizes.medium.file;
            const large = item.sizes.large.file;
            const orig = item.url;

            return {
                id: item.id,
                title: item.title,
                medium: url + "/" + medium,
                large: url + "/" + large,
                orig: orig,
            };
        });

        // Update state after dropdown select
        const onTagSelect = (event) => {
            setTag(event.target.value);
        };

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ItemTagSelector
                        tag={tag}
                        handleChange={onTagSelect}
                        tags={meta.tags}
                    />
                </Grid>
                <Grid item xs={12}>
                    {!itemSingle ? (
                        <ItemList
                            items={itemAll}
                            handleItemClick={setItemSingle}
                        />
                    ) : (
                        <ItemSingle
                            itemSingle={itemSingle}
                            handleSingleClose={setItemSingle}
                        />
                    )}
                </Grid>
            </Grid>
        );
    } else {
        // Show skeletons during thunk function execution
        const skeletons = new Array(6).fill("a").map((v, i) => {
            return (
                <Grid item xs={4} key={i}>
                    <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={120}
                    />
                </Grid>
            );
        });

        return (
            <Grid container spacing={2}>
                {skeletons}
            </Grid>
        );
    }
};

export default Gallery;
