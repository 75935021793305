import React from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Chip } from "@mui/material";

// Manage Profile Chip/Button in Appbar/Toolbar
export const ManageAccountChip = styled(
    React.forwardRef((props, ref) => (
        <Chip
            {...props}
            clickable
            color="secondary"
            component={Link}
            icon={props.icon}
            label={props.label || "Name"}
            ref={ref}
            to="/account"
            variant="outlined"
        />
    ))
)(({ theme }) => ({
    margin: theme.spacing(1),
    "& .MuiChip-label": {
        fontSize: "0.6rem",
    },
}));

// Reading Time and UpdatedAt Meta info on Portfolio Single Page
export const PortfolioSingleMetaChip = styled((props) => (
    <Chip
        {...props}
        component="div"
        icon={props.icon}
        label={props.label || "Name"}
        variant="outlined"
    />
))(({ theme }) => ({
    marginRight: theme.spacing(2),
    padding: theme.spacing(2),
    "& .MuiChip-icon": {
        color: theme.palette.grey[600],
    },
    "& .MuiChip-label": {
        color: theme.palette.grey[600],
        fontSize: "0.8rem",
        paddingLeft: theme.spacing(2),
    },
}));
