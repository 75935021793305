import { createTheme } from "@mui/material/styles";

// Customize MUI's default theme, used by ThemeProvider in App.js
export const createCustomTheme = (modeToken) =>
    createTheme({
        ...modeToken,
        //  Customize component styles
        // components: {
        //     // Name of component
        //     MuiTooltip: {
        //         styleOverrides: {
        //             tooltip: {
        //                 fontSize: "2rem",
        //             },
        //         },
        //     },
        // },
    });
