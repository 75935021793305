import React, { forwardRef } from "react";

import { useTranslation } from "react-i18next";

import { Dialog, Slide, Grid, Box, Typography, Avatar } from "@mui/material";

import { ContactForm } from "./Forms";

import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";

// Dialog animation
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// Contact Dialog
const Contact = ({ open, toggleOpen }) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => toggleOpen()}
        >
            <Grid
                container
                sx={{
                    maxWidth: "30rem",
                    padding: "1rem",
                }}
            >
                {/* Title */}
                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography component="div" variant="h6" align="center">
                            {t("forms.contact")}
                        </Typography>
                        <Avatar sx={{ m: 2, bgcolor: "secondary.main" }}>
                            <MarkunreadMailboxIcon />
                        </Avatar>
                    </Box>
                </Grid>
                {/* Form */}
                <Grid item xs={12}>
                    <ContactForm toggleOpen={toggleOpen} />
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default Contact;
