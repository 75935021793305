import React from "react";

import { FaEnvelope, FaPhoneAlt, FaGlobe } from "react-icons/fa";

const Contact = ({ contact }) => {
    const contacts = (
        <ul className="list-unstyled contact-list">
            <li className="email">
                <FaEnvelope />
                <a href={"mailto: " + contact.email}>{contact.email}</a>
            </li>
            <li className="phone">
                <FaPhoneAlt />
                <a href={"tel: " + contact.phone1}>{contact.phone1}</a>
            </li>
            <li className="website">
                <FaGlobe />
                <a
                    href={"https://" + contact.web1}
                    target="_blank"
                    rel="noreferrer"
                >
                    {contact.web1}
                </a>
            </li>
        </ul>
    );

    return (
        <div className="sidebar-container">
            <h2 className="title">Contacts</h2>
            {contacts}
        </div>
    );
};

export default Contact;
