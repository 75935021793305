import * as React from "react";
import { styled, alpha, useTheme } from "@mui/material/styles";
import { AppBar as MuiAppBar } from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";

// Custom AppBar Styles
export const AppBar = styled((props) => (
    <MuiAppBar
        {...props}
        color="transparent"
        component="div"
        position="sticky"
    />
))(({ theme }) => ({
    height: "10vh",
    paddingTop: theme.spacing(1),
}));

// useScrollTrigger hook to animate AppBar on scrollable pages
export const ElevationOnScroll = (props) => {
    // Access to custom theme definitions
    const theme = useTheme();
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 10,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        // AppBar also supports the MUI Paper props!
        sx: {
            // Set background if page is scrollable
            bgcolor: trigger
                ? alpha(theme.palette.background.paper, 0.8)
                : "transparent",
            // Add box-shadow to AppBar Paper component if page is scrollable
            boxShadow: trigger ? "0px 10px 15px 10px rgb(0 0 0 / 14%)" : "none",
        },
    });
};
