import React from "react";
import MuiIconButton from "@mui/material/IconButton";

const IconButton = (props) => {
    const { children, color = "secondary", size = "small", ...rest } = props;

    return (
        <MuiIconButton color={color} size={size} {...rest}>
            {children}
        </MuiIconButton>
    );
};

export default IconButton;
