import React from "react";

// Form root component with form controls as children
const Form = (props) => {
    return (
        <form
            onSubmit={props.onSubmit}
            noValidate={true}
            autoComplete="off"
            style={{ width: "100%" }}
        >
            {props.children}
        </form>
    );
};

export default Form;
