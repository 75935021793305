import { useEffect, useMemo, createContext } from "react";
import { useSelector, useDispatch } from "react-redux";

// Material UI (MUI) theme provider
import { ThemeProvider } from "@mui/material/styles";
// Change the CSS injection order so that classes from useStyles hook have precedence in MUI components
import { StyledEngineProvider } from "@mui/material/styles";
// Customized light & dark mode colors
import { getModeTokens } from "../../mui/colors";
// Customized MUI theme
import { createCustomTheme } from "../../mui/theme";

// Thunk function for async http request and action dispatch
import { startCheckUserToken, startSetUserLanguage } from "../../thunks/user";

// Selectors
import { getIsLoading } from "../../selectors/loading";
import { getUserColorMode } from "../../selectors/user";
import { getWpContentError } from "../../selectors/wpContent";

// Action creators
import { endLoading } from "../../actions/loading";

// Components
import LoadingSpinner from "../LoadingSpinner/LoeadingSpinner";
import Layout from "../../components/Layout/Layout";

// Pages
import BackendError from "../../pages/BackendError/BackendError";

const App = () => {
    const dispatch = useDispatch();

    // Subscribe to loading state
    const isLoading = useSelector((state) => getIsLoading(state));

    // Subscribe to any WP API error occured when requesting WP content, default is null
    const wpContentError = useSelector((state) => getWpContentError(state));

    /*
     * MUI config
     */
    const ColorModeContext = createContext({ toggleColorMode: () => {} });
    // Subscribe to user's theme state, either 'light' or 'dark' mode
    const colorMode = useSelector((state) => getUserColorMode(state));
    // Update muiTheme only if the mode changes
    const muiTheme = useMemo(
        () => createCustomTheme(getModeTokens(colorMode)),
        [colorMode]
    );

    useEffect(() => {
        // update language state based on LanguageDetector result
        dispatch(startSetUserLanguage());
        // check user status and initialise store with wordpress content (config + pages)
        dispatch(startCheckUserToken());
        // check for API error message and if we have one, stop loading indicator
        if (wpContentError) dispatch(endLoading());
    }, [dispatch, wpContentError]);

    // JSX content to render
    let content = isLoading ? (
        <LoadingSpinner />
    ) : wpContentError ? (
        <BackendError />
    ) : (
        <Layout />
    );

    // Render component tree
    return (
        <StyledEngineProvider injectFirst>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={muiTheme}>{content}</ThemeProvider>
            </ColorModeContext.Provider>
        </StyledEngineProvider>
    );
};

export default App;
