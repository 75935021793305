import React from "react";

import { Box, IconButton } from "@mui/material";

import { Icons } from "../../../mui/icons";

const ItemSingle = ({ itemSingle, handleSingleClose }) => {
    return (
        <>
            <Box component="div" sx={{ position: "relative" }}>
                <img
                    alt={itemSingle.title}
                    onClick={() => handleSingleClose(null)}
                    src={itemSingle.large}
                    style={{ width: "100%" }}
                />
                <IconButton
                    aria-label="close full view"
                    color="secondary"
                    onClick={() => handleSingleClose(null)}
                    sx={{ position: "absolute", top: 0, right: 8 }}
                >
                    <Icons.Close />
                </IconButton>
            </Box>
        </>
    );
};

export default ItemSingle;
