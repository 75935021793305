import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getPageBySection } from "../selectors/wpContent";
import { getUserLanguage } from "../selectors/user";

export const usePage = (section = "home") => {
    // Function for i18n translations using files in src/assets/locals
    const { t } = useTranslation();

    // Get current user language from Redux Store
    const lang = useSelector((state) => getUserLanguage(state));

    // Get page content from Redux Store
    const page = useSelector((state) => getPageBySection(state, section));

    // Throw error if page
    if (!page)
        throw new Error(
            `Page for section '${section}' could not be found in Redux store by usePage hook.`
        );

    // Array including page, translate function and current user language
    return [page, t, lang];
};
