import React from "react";

import { FaArchive } from "react-icons/fa";

const Projects = ({ projects }) => {
    const entries = projects.project.map((item) => {
        return (
            <div className="item" key={item.title}>
                <span className="title">{item.title}</span>
                <span className="tagline">{item.tagline}</span>
            </div>
        );
    });

    return (
        <div className="section">
            <h2 className="section-title">
                <span className="icon-holder">
                    <FaArchive />{" "}
                </span>
                {projects.title}
            </h2>
            <div className="intro">
                <p>{projects.intro}</p>
            </div>
            {entries}
        </div>
    );
};

export default Projects;
