import * as React from "react";
import { styled } from "@mui/material/styles";
import { Accordion as MuiAccordion } from "@mui/material";

// Custom Accordion Styles
export const Accordion = styled((props) => (
    <MuiAccordion {...props} square={true} />
))(({ theme }) => ({
    backgroundColor: "inherit",
    boxShadow: "none",
    padding: theme.spacing(1),
    "& .MuiAccordionSummary-content": {
        color: theme.palette.accent.main,
        flexGrow: 0,
    },
    "& svg, .Mui-expanded": {
        fill: theme.palette.accent.main,
        marginLeft: theme.spacing(1),
    },
}));
