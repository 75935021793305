import React from "react";

const Interests = ({ interests }) => {
    const entry = interests.map((item) => {
        return (
            <li key={item.interest}>
                <p className="heading" style={{ marginBottom: "10px" }}>
                    {item.interest}
                </p>{" "}
            </li>
        );
    });

    return (
        <div className="sidebar-container">
            <h2 className="title">Interests</h2>
            <div className="item">
                <ul className="list-unstyled">{entry}</ul>
            </div>
        </div>
    );
};

export default Interests;
