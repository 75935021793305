import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles"; // set css opacity

import { Box, ButtonGroup, Divider, Toolbar, useTheme } from "@mui/material";

// Thunk function to update redux store
import { startSetUserLanguage } from "../../thunks/user";
// Action creator used in dispatch method
import { setColorMode } from "../../actions/user";

// Selector functions
import { getUserColorMode } from "../../selectors/user";
import { getUserLanguage } from "../../selectors/user";

// Customized / styled MUI components
import { SettingButton, LogoAvatar } from "../../mui/display";
import { AppBar, ElevationOnScroll } from "../../mui/surfaces";

// App icons pool
import { Icons } from "../../mui/icons";

// Custom hook
import useToolbar from "./useToolbar";

const useStyles = makeStyles((theme) => ({
    // Mobile Menu Button
    menuBtn: {
        color: theme.palette.secondary.main,
        height: "2.5rem",
        width: "2.5rem",
    },
    // Mobile Drawer
    drawer: {
        // Container div
        "& .MuiPaper-root": {
            paddingTop: "10px",
            height: "100%",
        },
        // Nav Links
        "& .MuiListItem-root": {
            marginTop: "10px",
            marginBottom: "10px",
            // React NavLink active
            "&.active": {
                backgroundColor: alpha(theme.palette.action.active, 0.1),
                borderLeft: `3px solid ${theme.palette.secondary.main}`,
            },
        },
        // Nav Icons
        "& .MuiSvgIcon-root": {
            color: theme.palette.secondary.main,
            height: "2rem",
            width: "2rem",
        },
        // Dropdown Icons
        "& .MuiListItemButton-root .MuiSvgIcon-root": {
            color: theme.palette.secondary.main,
            height: "1.5rem",
            width: "1.5rem",
        },
        // Dropdown Text
        "& .MuiListItemButton-root .MuiListItemText-root .MuiTypography-root": {
            fontSize: "0.8rem",
        },
    },
    // Desktop Toolbar
    desktop: {
        backgroundColor: "transparent",
        flex: 1,
        justifyContent: "flex-end",
    },
    // Language & Theme Toggle
    settings: { marginLeft: "auto" },
    // Tooltips for MainNav Items
    tooltip: {
        "& .tooltip": { fontSize: "3rem" },
    },
    // MainNav Items
    navItems: {
        flexGrow: 1,
        justifyContent: "flex-end",
    },
    // MainNav Icons
    icon: {
        height: "2rem",
        width: "2rem",
    },
    // Dropdown Menu Family&Friends
    menu: {
        marginTop: "50px",
        "& .MuiListItemText-root .MuiTypography-root": {
            fontSize: "0.8rem",
        },
    },
}));

const Appbar = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const classes = useStyles();

    const [mobile, pages, menus, profile] = useToolbar(classes);

    // User's theme
    const colorMode = useSelector((state) => getUserColorMode(state));
    // User's language
    const lang = useSelector((state) => getUserLanguage(state));

    const toggleColorMode = () =>
        dispatch(setColorMode(colorMode === "light" ? "dark" : "light"));
    const toggleLanguage = () => {
        const newLang = lang === "de-DE" ? "en" : "de";
        dispatch(startSetUserLanguage(newLang));
        // Change language for correct ressource file in assets/locals
        i18n.changeLanguage(newLang);
    };

    return (
        <ElevationOnScroll {...props}>
            <AppBar>
                <Toolbar disableGutters>
                    {/* Homepage Logo */}
                    <Box
                        sx={{
                            display: { xs: "none", sm: "flex" },
                            paddingLeft: theme.spacing(0.5),
                        }}
                    >
                        {<LogoAvatar className={classes.avatar} />}
                    </Box>

                    {/* Mobile Toolbar Content */}
                    <Box
                        sx={{
                            display: { xs: "flex", sm: "none" },
                        }}
                    >
                        {mobile}
                    </Box>

                    {/* Desktop Toolbar Content */}
                    <Box
                        className={classes.desktop}
                        sx={{
                            display: { xs: "none", sm: "flex" },
                        }}
                    >
                        {/* Main NavLinks */}
                        {pages}

                        {/* Family & Friends Menu */}
                        {menus}
                    </Box>

                    {/* Manage Profile Chip */}
                    <Box
                        sx={{
                            display: { xs: "none", sm: "flex" },
                        }}
                    >
                        <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ m: 1 }}
                        />

                        {profile}
                    </Box>

                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ display: { xs: "none", sm: "flex" }, m: 1 }}
                    />

                    {/* Setting Buttons for Language and Theme */}
                    <Box className={classes.settings}>
                        <ButtonGroup orientation="vertical">
                            <SettingButton
                                icon={Icons.Brightness4}
                                onClick={toggleColorMode}
                                title={t(
                                    `navigation.${
                                        colorMode === "light" ? "dark" : "light"
                                    }`
                                )}
                            />

                            <SettingButton
                                icon={Icons.Language}
                                onClick={toggleLanguage}
                                title={t(
                                    `navigation.${
                                        lang === "de-DE" ? "english" : "german"
                                    }`
                                )}
                            />
                        </ButtonGroup>
                    </Box>
                </Toolbar>
            </AppBar>
        </ElevationOnScroll>
    );
};

export default Appbar;
