import axios from "axios";
import { constants } from "./constants";

/**
 *
 * Application wide configs for axios requests
 * multiple instances reflect custom REST API endpoints
 * usage: import {<instance-name>} from 'axios-wordpress'
 *
 */

// Public API endpoint (w/o JWT auth)
const publicAPI = axios.create({
    baseURL: `${constants.API_URL}:${constants.API_PORT}/wp-json/fidelius-online/v1/public`,
    timeout: constants.API_TIMEOUT,
});

// Private API endpoint (w/ JWT auth)
const privateAPI = axios.create({
    baseURL: `${constants.API_URL}:${constants.API_PORT}/wp-json/fidelius-online/v1/private`,
    timeout: constants.API_TIMEOUT,
});

// Authentication endpoint that returns JWT
const authAPI = axios.create({
    baseURL: `${constants.API_URL}:${constants.API_PORT}/wp-json/jwt-auth/v1`,
    timeout: constants.API_TIMEOUT,
});

// Standard WP endpoint w/ JWT auth
const userAPI = axios.create({
    baseURL: `${constants.API_URL}:${constants.API_PORT}/wp-json/wp/v2/users`,
    timeout: constants.API_TIMEOUT,
});

// Add request interceptor for Auth API to validate cookie token
authAPI.interceptors.request.use(
    (request) => {
        // Handle JSON.parse() exception if there is no cookie
        try {
            // extract JWT
            const { token } = JSON.parse(sessionStorage.getItem("user"));
            // add JWT to http request headers
            if (token) {
                request.headers["Authorization"] = "Bearer " + token;
            }
            return request;
        } catch (error) {
            return request;
        }
    },
    (error) => {
        console.log("Error in axios auth request interceptor: ", error);
        return Promise.reject(error);
    }
);

// Add request interceptor for private API
privateAPI.interceptors.request.use(
    (request) => {
        // extract JWT
        const { token } = JSON.parse(sessionStorage.getItem("user"));
        // add JWT to http request headers
        if (token) {
            request.headers["Authorization"] = "Bearer " + token;
        }
        return request;
    },
    (error) => {
        console.log("Error in axios private request interceptor: ", error);
        return Promise.reject(error);
    }
);

// Add request interceptor for user API
userAPI.interceptors.request.use(
    (request) => {
        // extract JWT
        const { token } = JSON.parse(sessionStorage.getItem("user"));
        // add JWT to http request headers
        if (token) {
            request.headers["Authorization"] = "Bearer " + token;
        }
        return request;
    },
    (error) => {
        console.log("Error in axios user request interceptor: ", error);
        return Promise.reject(error);
    }
);

// Currently not used: Add a response interceptor
privateAPI.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        // console.log("axios response: \n", response);
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        // console.log("axios-wordpress.js: response error: ", error);
        return Promise.reject(error);
    }
);

export { publicAPI, privateAPI, authAPI, userAPI };
