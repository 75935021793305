import React from "react";

import Profile from "./Profile/Profile";
import WhyMe from "./WhyMe/WhyMe";
import Contact from "./Contact/Contact";
import Education from "./Education/Education";
import Languages from "./Languages/Languages";
import Interests from "./Interests/Interests";
import Badges from "./Badges/Badges";

// import Toolbar from "../../../components/Cv/Toolbar/Toolbar";

const Sidebar = (props) => {
  return (
    <div className="sidebar-wrapper">
      {/* <Toolbar /> */}
      <Profile {...props} />
      <WhyMe {...props} />
      <Contact {...props} />
      <Education {...props} />
      <Languages {...props} />
      <Interests {...props} />
      <Badges {...props} />
    </div>
  );
};

export default Sidebar;
