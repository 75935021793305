import React from "react";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Button,
} from "@mui/material";

const ItemTagSelector = ({ tag, tags, handleChange }) => {
    return (
        <>
            <FormControl sx={{ minWidth: 120 }} size="small">
                <Select
                    labelId="tag-selector"
                    id="tag-selector"
                    value={tag}
                    label="Filter"
                    onChange={handleChange}
                >
                    <MenuItem value="">
                        <em>All</em>
                    </MenuItem>
                    {tags.map((tag) => (
                        <MenuItem value={tag}>{tag.toUpperCase()}</MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                    Choose a tag to see related images.
                </FormHelperText>
            </FormControl>
            <Button onClick={() => handleChange({ target: { value: "" } })}>
                Reset Filter
            </Button>
        </>
    );
};

export default ItemTagSelector;
