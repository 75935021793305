import React from "react";

import { Grid } from "@mui/material";

import { Text } from "../../../mui/typography/Typography";

const Downloads = (props) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Text>
                    Fugiat adipisicing eiusmod ipsum nostrud. Exercitation
                    incididunt nisi exercitation ut consequat ullamco laboris.
                    Do qui voluptate adipisicing et consectetur. Adipisicing
                    aliquip amet fugiat reprehenderit enim nostrud laboris
                    excepteur commodo excepteur sunt. Fugiat laboris mollit
                    laboris ex magna id deserunt est ex voluptate. Magna eu
                    occaecat dolor adipisicing ea mollit culpa. Minim occaecat
                    nostrud commodo dolore enim laborum deserunt ex
                    reprehenderit culpa. Proident officia qui in veniam non
                    pariatur quis aliquip consequat Lorem culpa enim ad anim. Ex
                    sint est consequat elit cupidatat elit veniam laboris aute
                    consequat sit et. Ullamco sint proident et ipsum ex
                    adipisicing laborum aliqua magna aute sunt minim. Ullamco
                    nisi duis duis ullamco. Consequat ad excepteur consequat
                    officia velit. Aliqua sint ex sunt officia proident esse
                    culpa. Minim fugiat veniam deserunt officia aliquip sit
                    occaecat. Ipsum cillum laboris non ad pariatur est dolore
                    occaecat. Excepteur tempor enim aliqua do enim pariatur
                    eiusmod adipisicing occaecat excepteur velit dolor et.
                    Deserunt anim proident quis eu reprehenderit. Sint consequat
                    laborum adipisicing aliqua nostrud pariatur culpa dolor aute
                    reprehenderit. Pariatur non do culpa duis ad ullamco sint
                    ex. Dolor labore proident pariatur nulla nulla ipsum.
                </Text>
            </Grid>
        </Grid>
    );
};

export default Downloads;
