import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { getUserColorMode } from "../../selectors/user";
import { getContactFormIsVisible } from "../../selectors/visibility";

import AppRoutes from "../../routes/AppRoutes";

import Nav from "../Nav/Appbar";
import Footer from "../../pages/Footer/Footer";

// Hidden DOM elements
import Contact from "../ContactForm/Contact";
import { ToastContainer } from "react-toastify";
import CookieBanner from "../CookieBanner/Banner";
import { toggleContactForm } from "../../actions/visibility";

// Styled MUI components
import { AppContainer, Page } from "../../mui/layout";

const Layout = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    // Check if we are on "/home" to define backgroundImage in AppContainer.js
    const ishome = location.pathname.includes("home") ? "true" : "false";

    const colorMode = useSelector((state) => getUserColorMode(state));
    const contactOpen = useSelector((state) => getContactFormIsVisible(state));

    // Toggle dark-theme class depending on user's scheme
    const addBodyClass = () => {
        document.body.classList.add("dark-theme");
    };
    const removeBodyClass = () => {
        document.body.classList.remove("dark-theme");
    };
    useEffect(() => {
        colorMode === "light" ? removeBodyClass() : addBodyClass();
    }, [colorMode]);

    return (
        <>
            {/* ishome attribute defines if current pathname === "/home" */}
            <AppContainer maxWidth="md" ishome={ishome}>
                <Nav />
                <Page>
                    <AppRoutes />
                </Page>
                <Footer />
            </AppContainer>

            {/* Hidden components */}
            <CookieBanner />
            <Contact
                open={contactOpen}
                toggleOpen={() => dispatch(toggleContactForm())}
            />
            <ToastContainer
                position="bottom-center"
                autoClose="3000"
                draggable={false}
            />
        </>
    );
};

export default Layout;
