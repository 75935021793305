import React from "react";

import ProgressBar from "../../../../components/ProgessBar/ProgressBar";

import { FaRocket } from "react-icons/fa";

const Skills = ({ skills }) => {
    let skillset = skills.skillset.map((skill, key) => {
        const { name: title, level } = skill;
        return <ProgressBar title={title} level={level} key={key} />;
    });

    return (
        <div className="section">
            <h2 className="section-title">
                <span className="icon-holder">
                    <FaRocket />
                </span>
                {skills.title}
            </h2>
            <div className="skillset">{skillset}</div>
        </div>
    );
};

export default Skills;
