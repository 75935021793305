import {
    SET_PUBLIC_WP_CONTENT,
    SET_PRIVATE_WP_CONTENT,
    CLEAR_PRIVATE_WP_CONTENT,
    SET_WP_CONTENT_ERROR,
} from "../actions/types";

// Default values for initialisation
import { wpContentDefaultState } from "../store/defaults";

// Reducer to set wordpress content as state in Redux store
const manageWpContentState = (state = wpContentDefaultState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_PUBLIC_WP_CONTENT:
            return {
                ...state,
                configs: payload.configs,
                pages: [...payload.pages], // pages tagged as "Public"
            };
        case SET_PRIVATE_WP_CONTENT:
            return {
                ...state,
                messages: payload.messages, // contact form messages
                pages: [...state.pages, ...payload.pages], // pages tagged as "Private"
                media: payload.media, // media library items array and meta data object
            };
        case CLEAR_PRIVATE_WP_CONTENT:
            const publicPages = state.pages.filter((page) =>
                page.categories.toLowerCase().includes("public")
            );
            return {
                ...state,
                messages: null,
                media: null,
                pages: publicPages,
            };

        case SET_WP_CONTENT_ERROR:
            return {
                ...state,
                error: payload,
            };

        default:
            return state;
    }
};

export default manageWpContentState;
