import React from "react";

import Summary from "./Summary/Summary";
import Experiences from "./Experiences/Experiences";
import Projects from "./Projects/Projects";
import Skills from "./Skills/Skills";

const Main = (props) => {
    return (
        <div className="main-wrapper">
            <Summary {...props} />
            <Experiences {...props} />
            <Projects {...props} />
            <Skills {...props} />
        </div>
    );
};

export default Main;
