import { createStore, combineReducers, applyMiddleware } from "redux";

// Library to persist the redux store object
import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import storageSession from "redux-persist/lib/storage/session";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

// Redux DevTools support in Chrome Browser
import { composeWithDevTools } from "redux-devtools-extension";

// Async function middleware with Redux, https://redux.js.org/tutorials/fundamentals/part-6-async-logic
import thunkMiddleware from "redux-thunk";

// Reducers, one per Redux store slice (root-level attributes)
import manageUserState from "../reducers/user";
import manageWpContentState from "../reducers/wpContent";
import manageLoadingState from "../reducers/loading";
import manageVisibilityState from "../reducers/visibility";

// Enable thunks as middleware
const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware));

// persist wpContent slice in SessionStorage
const persistConfig = {
    key: "persitedStore",
    storage: storageSession,
    stateReconciler: autoMergeLevel2,
    whitelist: ["wpContent"],
};

// Combine single reducers
const rootReducer = combineReducers({
    user: manageUserState,
    wpContent: manageWpContentState,
    loading: manageLoadingState,
    visibility: manageVisibilityState,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Store has the ability to accept thunk functions in `dispatch` due to composedEnhancer parameter
const store = createStore(persistedReducer, composedEnhancer);

// Provides utility functions to manage the persistedStore
const persistor = persistStore(store);

export { store as default, persistor };
