import React from "react";
import ReactDOM from "react-dom";

// Global SCSS styles
import "./assets/scss/styles.scss";
// SCSS variables
// import variables from "./assets/scss/exports.module.scss";

// Redux store provider
import { Provider } from "react-redux";

// // Material UI (MUI) theme provider
// import { ThemeProvider } from "@mui/material/styles";
// // Customized MUI theme
// import muiTheme from "./mui/theme";
// // Change the CSS injection order so that classes from useStyles hook have precedence in MUI components
// import { StyledEngineProvider } from "@mui/material/styles";

// React router
import { BrowserRouter } from "react-router-dom";
// Global style settings for react-icons
import { IconContext } from "react-icons";
// Reference to the redux store
import store from "./store/store";
// Delays app rendering until persisted state has been retrieved and saved to redux
import { PersistGate } from "redux-persist/integration/react";
// Provides utility functions for persistedStore like flush() or purge()
import { persistor } from "./store/store";
// Multi-Language Support through i18next
// eslint-disable-next-line
import i18n from "./i18nextInit";
// Higher order component
import App from "./components/App/App";
// Configurations for the app
import { constants } from "./configs/constants";

// Required for redirects outside of components
// export const history = createBrowserHistory();

// centrally configured react-icon styles
const iconStyles = {
    className: "icon",
    size: "1.5rem",
};

/*
 *
 * Content to render
 * Change basename to e.g. "/test" if App is served from sub-directory
 * Needs to match with "homepage" attribute in package.json
 *
 */
const jsx = (
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter basename="/">
                    <IconContext.Provider value={iconStyles}>
                        <App />
                    </IconContext.Provider>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

// Render App Component
ReactDOM.render(jsx, document.getElementById("root"));

// Subscribe and log redux store changes to console
if (constants.SHOW_REDUX_STORE_UPDATES) {
    store.subscribe(() => {
        const state = store.getState();
        console.log(state);
    });
}
