// User actions
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_COLOR_MODE = "SET_COLOR_MODE";
export const ACCEPTED_COOKIES = "ACCEPTED_COOKIES";
export const SET_ROLES = "SET_ROLES";

// Content actions
export const SET_PUBLIC_WP_CONTENT = "SET_PUBLIC_WP_CONTENT";
export const SET_PRIVATE_WP_CONTENT = "SET_PRIVATE_WP_CONTENT";
export const CLEAR_PRIVATE_WP_CONTENT = "CLEAR_PRIVATE_WP_CONTENT";
export const SET_WP_CONTENT_ERROR = "SET_WP_CONTENT_ERROR";

// Loading actions
export const START_LOADING = "START_LOADING";
export const END_LOADING = "END_LOADING";

// Visibility actions
export const TOGGLE_MENU = "TOGGLE_MENU";
export const TOGGLE_FOOTER = "TOGGLE_FOOTER";
export const TOGGLE_CONTACTFORM = "TOGGLE_CONTACTFORM";

// Device type actions
export const SET_MOBILE_DEVICE = "SET_MOBILE_DEVICE";
export const SET_DESKTOP_DEVICE = "SET_DESKTOP_DEVICE";
