// Thunk functions for asynchronous tasks that dispatch actions afterwards
import { constants } from "../configs/constants";
import { publicAPI, privateAPI } from "../configs/axios-wordpress";
import { setPublicWpContent, setPrivateWpContent } from "../actions/wpContent";
import { startLoading, endLoading } from "../actions/loading";
import { fetchApiData } from "../services/fetchApiData";
import { getConfigs, getPages } from "../selectors/wpContent";

// Fetch public WP content and update state
export const startGetPublicWpContent = () => {
    return async (dispatch, getState) => {
        // get current state
        const state = getState();
        let configs = getConfigs(state);
        let pages = getPages(state);
        // check for persisted state values
        if (configs && pages && constants.USE_PERSISTED_CONTENT) {
            console.log(
                "Using persisted state values for configs and pages..."
            );
            // change default loading state
            dispatch(endLoading());
        } else {
            // No persisted content available -> fetch it from WP API
            try {
                // set isLoading state to true
                dispatch(startLoading());
                // return array with config and page response
                const response = await fetchApiData(
                    ["configs", "pages"],
                    publicAPI
                );
                const [confRes, pageRes] = response;
                // create payload object for dispatch
                const payload = {
                    configs: createConfigObject(confRes.data),
                    pages: pageRes.data,
                };
                // call action creator to update state
                dispatch(setPublicWpContent(payload));
                // set isLoading state to false
                dispatch(endLoading());
            } catch (error) {
                console.log("Error", error);
                // set isLoading state to false and display <BackendError /> component
                dispatch(endLoading());
            }
        }
    };
};

// Fetch privat WP content and update state
export const startGetPrivateWpContent = (userData = {}) => {
    return async (dispatch, getState) => {
        try {
            // set isLoading state to true
            dispatch(startLoading());
            // Request private WP content for authenticated users
            const pages = await privateAPI.get("/pages");
            // Returns array of media items and object of media meta data
            const media = await privateAPI.get("/media");
            // Request contact form messages only for administrator account
            let messages = [];
            if (userData.email === constants.ADMIN_EMAIL) {
                const res = await privateAPI.get("/messages");
                messages = res.data;
            }
            if (!pages.data || !media.data) {
                console.log("Could not fetch private content from WP API.");
                throw new Error();
            }
            const payload = {
                pages: pages.data,
                media: media.data,
                messages: messages,
            };
            // call action creator to update state
            dispatch(setPrivateWpContent(payload));
            // set isLoading state to false
            dispatch(endLoading());
            return;
        } catch (err) {
            console.log(err);
            return new Error(
                "Error while requesting private content from wordpress API."
            );
        }
    };
};

// Create object with config as keys
const createConfigObject = (arr) => {
    try {
        return arr.reduce(
            (acc, cur) => ({
                ...acc,
                [cur.slug]: JSON.parse(cur.acf.json),
            }),
            {}
        );
        // catch JSON parsing error
    } catch (err) {
        console.error(
            "Parsing of JSON data from wordpress API failed! Please check syntax of custom post type 'Configs' in Wordpress backend. ",
            err
        );
    }
};
