import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Link as ReactLink } from "react-router-dom";

import {
    Grid,
    Stack,
    Link,
    Divider,
    ButtonGroup,
    Button,
    Chip,
    useTheme,
} from "@mui/material";

import { PageTitle2, Text } from "../../mui/typography/Typography";

import { usePage } from "../../hooks/usePage";

import { getRouteConfig } from "../../selectors/wpContent";

import { Icons } from "../../mui/icons";
import { StandardTooltip } from "../../mui/display/Tooltip";

const Content = ({ toggleDrawer, openContactForm }) => {
    // onClick handler to close Footer Drawer
    const closeDrawer = toggleDrawer(false);

    // Get WP content and translation function
    const [page, t] = usePage("footer");

    const theme = useTheme();

    // State mgmt for Tooltip content
    const [tooltipTitle, setTooltipTitle] = useState(t("footer.copy_content"));

    const routeConfig = useSelector((state) =>
        getRouteConfig(state).filter((route) => route.showInFooter)
    );

    // Extract Links to be displayed in Footer
    const navLinks = routeConfig.map((link, key) => {
        if (!["/impressum", "/datenschutz"].includes(link.to)) {
            return (
                <Link
                    color="inherit"
                    component={ReactLink}
                    key={key}
                    onClick={closeDrawer}
                    to={link.to}
                    underline="none"
                >
                    {t(`navigation.${link.label}`)}
                </Link>
            );
        } else {
            return null;
        }
    });

    // Function to offer phone number and email address to be copied
    const copyToClipboard = async (content) => {
        // Copy to Clipboard
        await navigator.clipboard.writeText(content);
        // Save initial title
        const initTitle = tooltipTitle;
        // Change title
        setTooltipTitle(t("footer.content_copied"));
        // Revert back to initial title
        setTimeout(() => {
            setTooltipTitle(initTitle);
        }, 2000);
    };

    // Deconstruct relevant content from page
    const { aboutThisTitle, aboutThisText, location, phone, email } =
        page.acfKeyValues;

    return (
        <Grid container spacing={2}>
            <Grid item xs={6} sm={3}>
                <Stack>
                    <Text
                        sx={{
                            fontSize: "1.5rem",
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        Steffen
                        <span style={{ color: theme.palette.accent.main }}>
                            Fidelius
                        </span>
                    </Text>
                    <Text sx={{ fontSize: "0.8rem", mt: 0.5 }}>
                        <Stack
                            spacing={0.5}
                            direction="row"
                            divider={
                                <Divider orientation="vertical" flexItem />
                            }
                        >
                            {navLinks}
                        </Stack>
                    </Text>
                    <Button
                        color="secondary"
                        onClick={() => {
                            openContactForm();
                            closeDrawer();
                        }}
                        sx={{ fontSize: "0.8rem", mt: 3 }}
                        to="/contact"
                        variant="contained"
                    >
                        {t("footer.leave_a_message")}
                    </Button>
                </Stack>
            </Grid>

            <Grid item xs={6} sm={3}>
                <Stack spacing={1}>
                    <Chip
                        variant="outlined"
                        icon={<Icons.LocationOn />}
                        label={location}
                        sx={{ justifyContent: "flex-start", width: "100%" }}
                    />
                    <StandardTooltip title={tooltipTitle}>
                        <Chip
                            variant="outlined"
                            icon={<Icons.PhoneAndroid />}
                            label={phone}
                            onClick={() => copyToClipboard(phone)}
                            sx={{ justifyContent: "flex-start", width: "100%" }}
                        />
                    </StandardTooltip>
                    <StandardTooltip title={tooltipTitle}>
                        <Chip
                            variant="outlined"
                            icon={<Icons.AlternateEmail />}
                            label={email}
                            onClick={() => copyToClipboard(email)}
                            sx={{ justifyContent: "flex-start", width: "100%" }}
                        />
                    </StandardTooltip>
                </Stack>
            </Grid>

            <Grid item xs={6} sm={3}>
                <PageTitle2
                    variant="h6"
                    sx={{ paddingBottom: theme.spacing(1), paddingLeft: 0 }}
                >
                    {aboutThisTitle}
                </PageTitle2>
                <Text sx={{ fontSize: "0.8rem" }}>{aboutThisText}</Text>
            </Grid>

            <Grid item xs={6} sm={3}>
                <ButtonGroup
                    variant="text"
                    color="secondary"
                    orientation="vertical"
                    sx={{ alignItems: "center" }}
                >
                    <Button
                        component={ReactLink}
                        onClick={closeDrawer}
                        sx={{ fontSize: { xs: "0.66rem", sm: "0.9rem" } }}
                        to="/datenschutz"
                    >
                        {t("footer.privacy_policy")}
                    </Button>
                    <Button
                        component={ReactLink}
                        onClick={closeDrawer}
                        sx={{ fontSize: { xs: "0.66rem", sm: "0.9rem" } }}
                        to="/impressum"
                    >
                        {t("footer.legal_details")}
                    </Button>
                </ButtonGroup>
            </Grid>
        </Grid>
    );
};

export default Content;
