import React from "react";
import TextField from "@mui/material/TextField";

const Input = (props) => {
    const {
        name,
        id,
        color = "secondary",
        variant = "outlined",
        label = "Label goes here",
        size = "small",
        value,
        onChange,
        ...rest
    } = props;
    return (
        <TextField
            name={name}
            id={id}
            color={color}
            variant={variant}
            label={label}
            size={size}
            value={value}
            onChange={onChange}
            {...rest}
        />
    );
};

export default Input;
