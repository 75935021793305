import { loadingDefaultState } from "../store/defaults";
import { START_LOADING, END_LOADING } from "../actions/types";

// Reducer to change loading states in Redux store
const manageLoadingState = (state = loadingDefaultState, action) => {
    switch (action.type) {
        case START_LOADING:
            return {
                isLoading: true,
            };

        case END_LOADING:
            return {
                isLoading: false,
            };

        default:
            return state;
    }
};

export default manageLoadingState;
