import { visibilityDefaultState } from "../store/defaults";
import { TOGGLE_CONTACTFORM } from "../actions/types";

// Reducer to change menu/form states in Redux store
const manageMenuState = (state = visibilityDefaultState, action) => {
    switch (action.type) {
        case TOGGLE_CONTACTFORM:
            return {
                ...state,
                contactForm: !state.contactForm,
            };

        default:
            return state;
    }
};

export default manageMenuState;
