/*
 * DEV Config
 */
const dev = {
  API_URL: "https://api.ddnss.org",
  API_PORT: 443,
  API_TIMEOUT: 3000,
  COOKIE_BANNER_TIMEOUT: 1000,
  USE_PERSISTED_CONTENT: false,
  SHOW_REDUX_STORE_UPDATES: true,
  ADMIN_EMAIL: "mail@fidelius-online.de",
};

/*
 * PROD Config
 */
const prod = {
  API_URL: "https://api.ddnss.org",
  API_PORT: 443,
  API_TIMEOUT: 3000,
  COOKIE_BANNER_TIMEOUT: 10000,
  USE_PERSISTED_CONTENT: true,
  SHOW_REDUX_STORE_UPDATES: false,
  ADMIN_EMAIL: "mail@fidelius-online.de",
};

// "npm run dev" will set NODE_ENV environment variable to "development"
// which then exports variable "constants" with dev values, "npm run build"
// will export prod config
export const constants = process.env.NODE_ENV === "development" ? dev : prod;
