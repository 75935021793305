import React from "react";
// import { makeStyles } from "@mui/styles";

import Logout from "./Logout/Logout";
import ProfileForm from "./Forms";
import { getUserProfile, getUserRoles } from "../../selectors/user";
import { useSelector } from "react-redux";
import { Grid, Paper } from "@mui/material";

// const useStyles = makeStyles((theme) => ({
//     formContent: {
//         margin: theme.spacing(2),
//         padding: theme.spacing(2),
//     },
// }));

const Account = () => {
    const profile = useSelector((state) => getUserProfile(state));
    const roles = useSelector((state) => getUserRoles(state));
    // const classes = useStyles();
    return (
        <Grid container spacing={3} component={Paper} sx={{ mt: 5 }}>
            <Grid item xs={12}>
                <Logout />
            </Grid>
            <Grid item xs={12}>
                <ProfileForm profile={profile} roles={roles} />
            </Grid>
        </Grid>
    );
};

export default Account;
