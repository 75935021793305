import React, { useEffect, useRef, useState } from "react";

const TypingCarousel = (props) => {
    // defaults if props are missing
    const {
        txtFixed = "Fixed text part",
        txtCarousel = [
            "carousel item 1.",
            "carousel item 2.",
            "carousel item 3.",
        ],
        period = 1000,
    } = props;

    const timeoutId = useRef();
    const [text, setText] = useState("");

    useEffect(() => {
        let loopNum = 0; // array index
        let isDeleting = false; // direction of cursor

        // function writes and deletes phrases in txtCarousel array
        const tick = () => {
            let i = loopNum % txtCarousel.length; // reset i to zero if loopNum === txtCarousel.length
            let fullTxt = txtCarousel[i]; // get next phrase
            let beg = true; // phrase typing started
            let end = false; // phrase typing completed

            if (isDeleting) {
                setText((prevState) => {
                    const newText = fullTxt.substring(0, prevState.length - 1);
                    beg = newText === ""; // true if old phrase was completely erased
                    return newText;
                });
            } else {
                setText((prevState) => {
                    const newText = fullTxt.substring(0, prevState.length + 1);
                    end = newText === fullTxt; // true if phrase is complete
                    return newText;
                });
            }

            // writing speed
            let delta = 150 - Math.random() * 100;

            // deleting speed, faster than writing
            if (isDeleting) {
                delta /= 2.5;
            }

            // writing finished
            if (!isDeleting && end) {
                delta = period;
                isDeleting = true;
                // deleting finsished
            } else if (isDeleting && beg) {
                delta = 500;
                isDeleting = false;
                loopNum++;
            }

            // re-run tick function
            timeoutId.current = setTimeout(() => {
                tick();
            }, delta);
        };

        // initial call
        tick();

        // clear if component gets unmounted
        return () => clearTimeout(timeoutId.current);
    }, [period, txtCarousel]);

    return (
        <>
            <div className="fixed">{txtFixed}</div>
            <div className="dynamic">
                <span className="cursor">{text}</span>
            </div>
        </>
    );
};

export default TypingCarousel;
