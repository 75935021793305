import React, { useEffect, useState } from "react";
import { useRef } from "react";

import {
    FaBirthdayCake,
    FaAt,
    FaPhone,
    FaMapMarkedAlt,
    FaPause,
    FaPlay,
} from "react-icons/fa";

import styles from "./IconCarousel.module.scss";

// Parent Component
const IconCarousel = ({
    dob = "23/12/72",
    email = "mail@fidelius-online.de",
    phone = "+49 173 737 99 99",
    location = "Neuss (NRW)",
    width = "200px",
    customInterval = 3000,
}) => {
    const icons = [
        {
            fig: <FaBirthdayCake />,
            caption: dob,
        },
        {
            fig: <FaAt />,
            caption: email,
        },
        {
            fig: <FaPhone />,
            caption: phone,
        },
        {
            fig: <FaMapMarkedAlt />,
            caption: location,
        },
    ];

    const style = {
        minWidth: width,
    };

    let intervalRef = useRef();

    const [play, setPlay] = useState(true);

    const iconLength = icons.length;
    const [position, setPosition] = useState(0);

    const handleClick = () => {
        clearInterval(intervalRef.current);
        setPlay((prev) => !prev);
    };

    const renderedIcons = icons.map((icon, index) => (
        <div className={`${styles.slide} ${styles.fade}`} key={index}>
            {icon.fig}
            {/* <i className={`fas ${icon.fig} fa-2x`}></i> */}
            <div className={styles.text}>{icon.caption}</div>
        </div>
    ));

    useEffect(() => {
        if (play) {
            intervalRef.current = setInterval(() => {
                setPosition((prev) => {
                    return prev >= iconLength - 1 ? 0 : prev + 1;
                });
            }, customInterval);
            return () => clearInterval(intervalRef.current);
        }
    }, [iconLength, customInterval, play]);

    return (
        <>
            <div
                className={styles.iconcarousel__container}
                style={style}
                onClick={handleClick}
            >
                <div className={styles.playstatus}>
                    {play ? (
                        <span className={styles.playicon}>
                            <FaPause />{" "}
                        </span>
                    ) : (
                        <span className={styles.playicon}>
                            <FaPlay />
                        </span>
                    )}
                </div>

                <Icon icons={renderedIcons} position={position} />
            </div>
        </>
    );
};

// Child Component
const Icon = ({ icons, position }) => {
    // progress dots below image
    const renderDots = () => {
        let index = 0;
        const dots = icons.map(() => {
            let isActive = index === position ? true : false;
            index++;
            return (
                <span
                    className={
                        isActive ? `${styles.dot} ${styles.active}` : styles.dot
                    }
                    key={index}
                ></span>
            );
        });
        return dots;
    };

    return (
        <div>
            {/* render slide at given position in array */}
            {icons[position]}{" "}
            <div style={{ textAlign: "center" }}>{renderDots()} </div>
        </div>
    );
};

export default IconCarousel;
