import React from "react";
import axios from "axios";

import { IconButton } from "@mui/material";

import { Icons } from "../../../mui/icons";

const ItemDownload = ({ item }) => {
    // Download binary data of image and open dialog for user
    const triggerSaveAsDialog = () => {
        axios({
            url: item.orig,
            method: "GET",
            responseType: "blob", // important
        })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const type = response.data.type.split("/");
                const ext = type[type.length - 1];
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${item.title}.${ext}`); // filename to download
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((err) =>
                console.log("Could not fetch and download gallery item!")
            );
    };

    return (
        <IconButton
            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
            aria-label={`download file ${item.title}`}
            onClick={() => triggerSaveAsDialog()}
        >
            <Icons.FileDownload />
        </IconButton>
    );
};

export default ItemDownload;
