// Define all components used throughout the App here!

import { Redirect } from "react-router-dom";

// Components of Main Pages
import Home from "../pages/Home/Home";
import Portfolio from "../pages/Portfolio/Portfolio";
import PortfolioSingle from "../pages/Portfolio/PortfolioSingle";
import Cv from "../pages/Cv/Cv";
import About from "../pages/About/About";
import Account from "../pages/Account/Account";
import DataPrivacy from "../pages/DataPrivacy/DataPrivacy";
import LegalDetails from "../pages/LegalDetails/LegalDetails";

// Components of Pages in Family & Friends Menu, ie "/ff/..."
import FamilyFriends from "../pages/FamilyFriends/FamilyFriends";
import Gallery from "../pages/FamilyFriends/Gallery/Gallery";
import Downloads from "../pages/FamilyFriends/Downloads/Downloads";
import Dashboard from "../pages/FamilyFriends/Dashboard/Dashboard";
import Projects from "../pages/FamilyFriends/Projects/Projects";

import Login from "../pages/Login/Login";
import Logout from "../components/Profile/Logout/Logout";
import NotFoundPage from "../pages/PageNotFound/PageNotFound";

import Playground from "../components/Playground/Playground";

// Mapping used in AppRoutes.js and FamilyFriends.js
export const routeComponents = {
    About,
    Cv,
    Home,
    Portfolio,
    PortfolioSingle,
    FamilyFriends,
    Gallery,
    Downloads,
    Dashboard,
    Projects,
    Account,
    Login,
    Logout,
    LegalDetails,
    DataPrivacy,
    NotFoundPage,
    Playground,
    Redirect,
};
