import { Container } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";

import { drawerBleeding } from "../../pages/Footer/Footer";

// Container around App content
export const AppContainer = styled((props) => {
    return <Container {...props} maxWidth="md" />;
})(({ theme, ...rest }) => {
    return {
        paddingBottom: drawerBleeding * 1.5,
        // Show background image on mobile view but only if "ishome" attribute in Layout.js is set to "true"
        [theme.breakpoints.up("xs")]: {
            backgroundImage:
                rest.ishome === "true" &&
                `linear-gradient(
                    45deg,
                    ${alpha(theme.palette.primary.main, 1)},
                    ${alpha(theme.palette.primary.main, 0.1)}
                ), url('images/bg-image-mobile.png')`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            minHeight: "100vh", // resize background image to full screen height
        },
        // Show clip-path gradient for all bigger viewports
        [theme.breakpoints.up("sm")]: {
            backgroundImage: "none",
            "&::after": {
                background: `linear-gradient(0deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                clipPath: "polygon(33% 0, 100% 0, 66% 100%, 0 100%)",
                content: "''", // ::before and ::after both require content
                height: "100%",
                left: 0,
                opacity: 0.3,
                position: "absolute",
                top: 0,
                width: "100%",
                zIndex: "-1",
            },
        },
    };
});
