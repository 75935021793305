import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend"; // load translations
import LanguageDetector from "i18next-browser-languagedetector"; // detect Browser language
import { i18nextPlugin } from "translation-check"; // Translation check plugin: http://localhost:3000?showtranslations

import translationEN from "./assets/locals/en/translation.json";
import translationDE from "./assets/locals/de/translation.json";

const fallbackLng = ["de"];
const availableLanguages = ["en", "de"];

const resources = {
    en: {
        translation: translationEN,
    },
    de: {
        translation: translationDE,
    },
};

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(i18nextPlugin)
    .init({
        resources,
        fallbackLng,

        detection: {
            checkWhitelist: true,
        },

        debug: false,

        whitelist: availableLanguages,

        interpolation: {
            escapeValue: false, // not required with React
        },
    });

export default i18n;
