import Input from "./Input";
import Button from "./Button";
import IconButton from "./IconButton";
import Checkbox from "./Checkbox";

const Controls = {
    Input,
    Button,
    IconButton,
    Checkbox,
};

export default Controls;
