import { styled } from "@mui/material/styles";
import { Tooltip } from "@mui/material";

// Tooltips for Main NavItems in Toolbar
export const NavItemTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props}
        arrow
        componentsProps={{ tooltip: { className: className } }}
    />
))(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[5],
    color: theme.palette.text.primary,
    fontSize: "1.2rem",
    ".MuiTooltip-arrow": {
        color: theme.palette.primary.main,
    },
}));

// Standard Tooltip, e.g. used in Footer.js for copy to clipboard feature
export const StandardTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props}
        arrow
        componentsProps={{ tooltip: { className: className } }}
    />
))(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[5],
    color: theme.palette.text.primary,
    fontSize: "0.8rem",
    ".MuiTooltip-arrow": {
        color: theme.palette.primary.main,
    },
}));
