import React from "react";
import { Route, Redirect } from "react-router-dom";

const PublicRoute = (props) => {
    const { path, component: Component } = props;

    // default catch-all route
    if (path === "*") {
        return <Route {...props} component={() => <Redirect to="/home" />} />;
    }

    return (
        <Route {...props}>
            {(props) => <Component {...props} key="component" />}
        </Route>
    );
};

export default PublicRoute;
