import React from "react";
import QRCode from "react-qr-code";

const Qrcode = ({ url, size = 64, caption }) => {
    return (
        <>
            <QRCode value={url} size={size} />
            <div style={{ marginTop: "1rem" }}>{caption}</div>
        </>
    );
};

export default Qrcode;
