import React from "react";
import parse from "html-react-parser"; // Rendering the HTML string, will be vulnerable to cross-site scripting attacks (XSS).

import { FaBriefcase } from "react-icons/fa";

const Experiences = ({ experiences }) => {
    const entries = experiences.experience.map((item) => (
        <div className="item" key={item.jobTitle}>
            <div className="meta">
                <div className="upper-row">
                    <div className="title">{item.jobTitle}</div>
                    <div className="time">{item.time}</div>
                </div>
                <div className="company">{item.company}</div>
            </div>
            <div className="details">{parse(item.details)}</div>
        </div>
    ));

    return (
        <div className="section">
            <h2 className="section-title">
                <span className="icon-holder">
                    <FaBriefcase />
                </span>
                {experiences.title}
            </h2>
            {entries}
        </div>
    );
};

export default Experiences;
