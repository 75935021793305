import React from "react";
import { useSelector } from "react-redux";

import { getUserIsLoggedIn } from "../../selectors/user";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

// check for route type, ie public versus private
const AppRoute = ({ isPrivate, ...rest }) => {
    // get current user state from store
    const isLoggedIn = useSelector((state) => getUserIsLoggedIn(state));

    if (isPrivate) {
        return <PrivateRoute {...rest} isLoggedIn={isLoggedIn} />;
    } else {
        return <PublicRoute {...rest} />;
    }
};

export default AppRoute;
