import variables from "../assets/scss/exports.module.scss"; // scss variables

/*
 *
 * Color values for MUI components
 * Return "palette" object depending on light vs. dark theme argument
 *
 */
export const getModeTokens = (mode) => ({
    palette: {
        mode,
        ...(mode === "light"
            ? {
                  // palette values for light mode
                  primary: {
                      main: variables.primaryColor,
                      light: variables.primaryColorLight,
                      dark: variables.primaryColorDark,
                  },
                  secondary: {
                      main: variables.secondaryColor,
                      light: variables.secondaryColorLight,
                      dark: variables.secondaryColorDark,
                  },
                  accent: {
                      main: variables.accentColor,
                      light: variables.accentColorLight,
                      dark: variables.accentColorDark,
                  },
                  text: {
                      primary: variables.textColorPrimary,
                      secondary: variables.textColorSecondary, // lighter
                      diabled: variables.textColorDisabled, // even lighter
                  },
              }
            : {
                  //  palette values for dark mode
                  primary: {
                      main: variables.darkPrimaryColor,
                      light: variables.darkPrimaryColorLight,
                      dark: variables.darkPrimaryColorDark,
                  },
                  secondary: {
                      main: variables.darkSecondaryColor,
                      light: variables.darkSecondaryColorLight,
                      dark: variables.darkSecondaryColorDark,
                  },
                  accent: {
                      main: variables.darkAccentColor,
                      light: variables.darkAccentColorLight,
                      dark: variables.darkAccentColorDark,
                  },
                  text: {
                      primary: variables.darkTextColorPrimary,
                      secondary: variables.darkTextColorSecondary, // darker
                      diabled: variables.darkTextColorDisabled, // even darker
                  },
                  background: {
                      default: variables.backgroundDefault,
                      paper: variables.backgroundPaper,
                  },
              }),
    },
});
