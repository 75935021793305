import React from "react";
// import routes from "../../routes/routeConfigMui.json";
import {
    NavItemTooltip,
    NavItemIconButton,
    ManageAccountChip,
} from "../../mui/display";
import { Icons } from "../../mui/icons";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    Box,
    Collapse,
    Drawer,
    IconButton,
    Menu,
    MenuItem,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getUserIsLoggedIn, getUserProfile } from "../../selectors/user";
import { getRouteConfig } from "../../selectors/wpContent";
import Logout from "../Profile/Logout/Logout";

// Hook providing the content of Mobile & Desktop Appbar
const useToolbar = (classes) => {
    const { t } = useTranslation();

    // State management and click handlers for Mobile Drawer and F&F Dropdown Menu
    const [showDrawer, setShowDrawer] = React.useState(false);
    const [showMenu, setShowMenu] = React.useState(false);
    // Used for dropdown menu positioning
    const [anchorEl, setAnchorEl] = React.useState(null);

    // Route config objects for main nav
    const routes = useSelector((state) =>
        getRouteConfig(state).filter((route) => route.showInMainNav)
    );

    // User auth state
    const isLoggedIn = useSelector((state) => getUserIsLoggedIn(state));
    // User Details
    const user = useSelector((state) => getUserProfile(state));

    // Simple Links
    const navLinks = routes.filter(
        (link) => link.showInMainNav && link.type === "link"
    );

    // Dropdown Link F&F
    const navDropdown = routes.filter(
        (link) => link.showInMainNav && link.type === "menu"
    );

    // Dropdown Menu content F&F
    const dropdownContent = navDropdown[0].items;

    // Manage Profile Link
    const manageAccountLink = routes.find((link) => link.label === "account");

    // Mobile drawer width
    const drawerWidth = "66%";

    /*
     * Mobile Nav Links
     */
    const mobileNav = (
        <>
            <IconButton onClick={() => setShowDrawer(!showDrawer)}>
                <Icons.Menu className={classes.menuBtn} />
            </IconButton>
            <Drawer
                anchor="left"
                className={classes.drawer}
                onClose={() => setShowDrawer(false)}
                open={showDrawer}
                PaperProps={{ sx: { width: drawerWidth } }}
                sx={{ width: drawerWidth }}
            >
                {/* Main Nav Links */}
                {navLinks.map((link) => {
                    const Icon = Icons[link.icon];
                    if (link.isPrivate && !isLoggedIn) return null;
                    return (
                        <ListItem
                            component={NavLink}
                            key={link.label}
                            onClick={() => setShowDrawer(false)}
                            to={link.to}
                        >
                            <ListItemIcon>
                                <Icon />
                            </ListItemIcon>
                            <ListItemText
                                primary={t(`navigation.${link.label}`)}
                            />
                        </ListItem>
                    );
                })}
                {/* Dropdown & Menu */}
                {navDropdown.map((link) => {
                    const Icon = Icons[link.icon];

                    return (
                        <div key={link.label}>
                            <ListItem
                                component={Link}
                                to="#"
                                onClick={() => setShowMenu(!showMenu)}
                            >
                                <ListItemIcon>
                                    <Icon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={t(`navigation.${link.label}`)}
                                />
                                {showMenu ? (
                                    <Icons.ExpandLess />
                                ) : (
                                    <Icons.ExpandMore />
                                )}
                            </ListItem>
                            <Collapse
                                in={showMenu}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List component="div" disablePadding>
                                    {dropdownContent.map((link) => {
                                        const Icon = Icons[link.icon];
                                        return (
                                            <ListItemButton
                                                component={Link}
                                                key={link.label}
                                                sx={{ pl: 4 }}
                                                onClick={() => {
                                                    setShowMenu(false);
                                                    setShowDrawer(false);
                                                }}
                                                to={link.to}
                                            >
                                                <ListItemIcon>
                                                    <Icon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={t(
                                                        `navigation.${link.label}`
                                                    )}
                                                />
                                            </ListItemButton>
                                        );
                                    })}
                                </List>
                            </Collapse>
                        </div>
                    );
                })}
                {/* Manage Profile Button */}
                {isLoggedIn ? (
                    <Box sx={{ marginTop: "auto" }}>
                        <Logout style={{ margin: "1rem" }} />
                    </Box>
                ) : null}
            </Drawer>
        </>
    );

    /*
     * Desktop Nav Links
     */
    const desktopNav = navLinks.map((link) => {
        const Icon = Icons[link.icon];
        // Show the Chip Button for Manage Profile instead of simple nav link
        if (link.isPrivate) return null;
        if (false) return null;
        return (
            <NavItemTooltip
                component={NavLink} // for .active class assignment
                key={link.label}
                title={t(`navigation.${link.label}`)}
            >
                <NavItemIconButton to={link.to}>{<Icon />}</NavItemIconButton>
            </NavItemTooltip>
        );
    });

    /*
     * Desktop Dropdown
     */
    const desktopDropdown = navDropdown.map((link) => {
        const Icon = Icons[link.icon];
        return (
            <div key={link.label}>
                {/* Menu Icon */}
                <NavItemTooltip title={t(`navigation.${link.label}`)}>
                    <NavItemIconButton
                        onClick={(e) => {
                            setShowMenu(!showMenu);
                            setAnchorEl(e.currentTarget);
                        }}
                    >
                        {<Icon className={classes.icon} />}
                    </NavItemIconButton>
                </NavItemTooltip>
                {/* Menu Content */}
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    className={classes.menu}
                    disableScrollLock={true} // prevent padding-right space
                    keepMounted
                    onClose={() => setShowMenu(false)}
                    open={showMenu && !showDrawer} // dont show desktop dropdown in mobile view
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    {dropdownContent.map((item) => {
                        const Icon = Icons[item.icon];
                        return (
                            <MenuItem
                                component={Link}
                                to={item.to || "#"}
                                key={item.label}
                                onClick={() => setShowMenu(false)}
                            >
                                <ListItemIcon>
                                    <Icon color="secondary" />
                                </ListItemIcon>
                                <ListItemText>
                                    {t(`navigation.${item.label}`)}
                                </ListItemText>
                            </MenuItem>
                        );
                    })}
                </Menu>
            </div>
        );
    });

    /*
     * Desktop Manage Profile Button
     */
    const manageAccount = isLoggedIn ? (
        <NavItemTooltip title={t(`navigation.${manageAccountLink.label}`)}>
            <ManageAccountChip icon={<Icons.Person />} label={user.firstName} />
        </NavItemTooltip>
    ) : null;

    // Return variables as Array
    return [mobileNav, desktopNav, desktopDropdown, manageAccount];
};

export default useToolbar;
