import React from "react";
import { Typography } from "@mui/material";

const Playground = () => {
    return (
        <>
            <Typography variant="h3" component="div">
                Playground
            </Typography>
            <Typography variant="body1" component="div">
                Body1
            </Typography>
            <Typography variant="body2" component="div">
                Body2
            </Typography>
            <Typography variant="subtitle1" component="div">
                Subtitle1
            </Typography>
            <Typography variant="subtitle2" component="div">
                Subtitle1
            </Typography>
        </>
    );
};

export default Playground;
