import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { getPortfolioPages } from "../selectors/wpContent";

import { Box, Link, Typography, Breadcrumbs } from "@mui/material";

import { Icons } from "../mui/icons";

// MUI Link using react-router-dom Link component
const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

// Render JSX for Breadcrumbs
export const useBreadcrumbs = () => {
    // Get access to current location
    const location = useLocation();
    // Create array of pathnames in URL, ie /protfolio/929
    const pathnames = location.pathname.split("/").filter((x) => x);
    // Get all Portfolio pages
    const portfolio = useSelector((state) => getPortfolioPages(state));
    // Create array with id and title per item
    const pathTitleArray = portfolio.map((item) => [
        [`/portfolio/${item.id}`],
        item.title,
    ]);
    // Create object from a Map and add the Title for the root path "/portfolio"
    const pathTitleMap = {
        ...Object.fromEntries(new Map(pathTitleArray)),
        "/portfolio": "Portfolio",
    };

    // Breadcrumb JSX
    const breadcrumbs = (
        <Box
            sx={{
                display: "grid",
                placeContent: "center",
                padding: "1rem 0 0 0",
            }}
        >
            <Breadcrumbs aria-label="breadcrumb">
                <LinkRouter underline="hover" color="inherit" to="/">
                    <Icons.Home />
                </LinkRouter>
                {pathnames.map((value, index) => {
                    const last = index === pathnames.length - 1;
                    const to = `/${pathnames.slice(0, index + 1).join("/")}`;

                    return last ? (
                        <Typography color="text.primary" key={to}>
                            {pathTitleMap[to]}
                        </Typography>
                    ) : (
                        <LinkRouter
                            underline="hover"
                            color="inherit"
                            to={to}
                            key={to}
                        >
                            {pathTitleMap[to]}
                        </LinkRouter>
                    );
                })}
            </Breadcrumbs>
        </Box>
    );

    return [breadcrumbs];
};
