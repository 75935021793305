// Action creators used by dispatch calls to manage state in store

import {
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGOUT_USER,
    SET_LANGUAGE,
    SET_COLOR_MODE,
    ACCEPTED_COOKIES,
    SET_ROLES,
} from "./types";

export const loginUserSuccess = (payload) => ({
    type: LOGIN_USER_SUCCESS,
    payload,
});

export const loginUserFailure = (payload) => ({
    type: LOGIN_USER_FAILURE,
    payload,
});

export const logoutUser = () => {
    return {
        type: LOGOUT_USER,
    };
};

export const setLanguage = (payload) => ({
    type: SET_LANGUAGE,
    payload,
});

export const setColorMode = (payload) => ({
    type: SET_COLOR_MODE,
    payload,
});

export const setAcceptedCookies = () => ({
    type: ACCEPTED_COOKIES,
});

export const setRoles = (payload) => ({
    type: SET_ROLES,
    payload,
});
