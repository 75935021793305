import React from "react";

import { Grid } from "@mui/material";

import { Text } from "../../../mui/typography/Typography";

const Dashboard = (props) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Text>
                    Eiusmod irure dolor ullamco labore. Qui cupidatat
                    exercitation amet nulla eiusmod cillum aute magna officia.
                    Nulla officia tempor est eiusmod exercitation minim. Elit
                    non culpa occaecat anim ex culpa ut proident dolore sit.
                    Labore amet magna nulla elit nostrud esse nulla
                    reprehenderit ex ullamco voluptate. Proident incididunt
                    commodo sunt mollit eu enim sunt velit qui consequat cillum
                    cupidatat. Excepteur irure culpa eu magna ut cillum
                    cupidatat commodo anim eiusmod. Aliqua et pariatur laborum
                    Lorem ex irure eiusmod Lorem irure laborum esse. In tempor
                    aute aute ullamco ipsum velit et laborum. Ea ea duis
                    incididunt veniam exercitation pariatur id sit nostrud
                    laborum ut. Amet ad irure tempor qui eiusmod. In eiusmod
                    aute aliqua enim ullamco reprehenderit. Nostrud dolor in
                    reprehenderit Lorem aliqua in commodo commodo veniam
                    excepteur officia dolore. Labore aute quis ea fugiat
                    ullamco. Lorem mollit labore magna in eiusmod est deserunt
                    minim adipisicing sit. Veniam irure laborum irure ullamco.
                    Minim labore consequat officia qui cillum proident. Nulla et
                    fugiat cillum esse velit nisi nisi cillum officia pariatur
                    magna. Sint pariatur cupidatat adipisicing ea proident
                    labore ex ex incididunt velit esse. Laborum consequat non
                    velit qui tempor id culpa laboris aliqua adipisicing.
                    Deserunt et aute aute consequat in consequat proident
                    cupidatat consequat et magna fugiat. Tempor voluptate
                    aliquip officia proident nostrud aliqua aute ea est veniam
                    do irure quis. Ipsum amet officia incididunt magna proident.
                    Velit ipsum dolor culpa irure non magna minim. Cillum id
                    officia commodo consequat in aliquip. Aute laborum
                    adipisicing do do do esse minim sit adipisicing. Deserunt
                    laboris consequat fugiat adipisicing in dolore commodo in ex
                    ut eu enim officia. Adipisicing incididunt nulla consectetur
                    et laboris do adipisicing minim ex officia cillum sit. Lorem
                    labore quis mollit cupidatat commodo sint officia. Fugiat
                    excepteur deserunt ullamco magna quis mollit sit commodo
                    tempor. Aliqua occaecat id irure dolore dolor nostrud. Ipsum
                    pariatur ex eu amet. Eiusmod et eiusmod excepteur nostrud ut
                    eiusmod ipsum ipsum labore. Dolor voluptate adipisicing ea
                    ut pariatur nulla minim sunt sunt deserunt in cupidatat eu.
                    Quis duis ad cupidatat sint aute cillum enim nulla consequat
                    commodo. Amet culpa excepteur duis reprehenderit. Esse
                    exercitation exercitation voluptate cupidatat enim ipsum.
                    Consectetur cupidatat eiusmod laborum in ipsum cillum ipsum
                    dolore consequat cillum laboris. Incididunt commodo sint
                    proident dolor sunt. Nulla eiusmod non consequat ut non nisi
                    tempor anim nostrud laborum tempor consequat. Voluptate elit
                    amet ut voluptate ut ad do ex Lorem consectetur laboris
                    proident nisi. Duis laborum aliquip deserunt magna officia
                    consectetur est incididunt id in aute. Reprehenderit non
                    enim sit Lorem duis ex dolor elit ut et quis do cillum.
                    Commodo mollit in est tempor adipisicing aliqua nulla do
                    anim ex quis occaecat. Nisi laborum qui commodo occaecat do
                    velit excepteur excepteur. Quis deserunt veniam laborum
                    velit ut nisi voluptate aliqua sint. Id Lorem mollit
                    consectetur et dolore ex adipisicing Lorem in id. Lorem
                    cupidatat Lorem eu laboris est qui mollit Lorem adipisicing.
                    Commodo laboris laborum enim esse esse aliqua commodo
                    deserunt esse aliqua nulla. Magna ex laboris elit deserunt
                    magna sunt enim. Sunt consectetur aute est irure duis dolore
                    eiusmod elit. Laborum sint excepteur incididunt tempor et
                    sit Lorem pariatur. Consectetur irure dolore occaecat
                    occaecat sint dolore ea voluptate sit. Reprehenderit elit
                    amet proident tempor qui pariatur voluptate officia
                    exercitation consectetur ea pariatur veniam. Veniam nisi
                    quis sit in minim incididunt anim. Tempor irure duis eu
                    velit proident ut consectetur occaecat deserunt irure. Magna
                    nulla esse occaecat veniam. Aliqua id in nulla laboris sunt.
                    Deserunt fugiat ullamco commodo Lorem sunt proident
                    exercitation ipsum. Et sunt sunt sint Lorem et esse quis
                    esse duis amet qui.
                </Text>
            </Grid>
        </Grid>
    );
};

export default Dashboard;
