import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getWpContentError } from "../../selectors/wpContent";

import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Button,
} from "@mui/material";
import { PageTitle2, Text } from "../../mui/typography/Typography";

import errorImage from "../../assets/images/error-man.jpg";

const BackendError = () => {
    const error = useSelector((state) => getWpContentError(state));
    const { t } = useTranslation();

    console.log(error);

    const handleRetry = () => {
        window.location.reload();
    };

    return (
        <Box
            sx={{
                alignItems: "center",
                display: "flex",
                height: "100vh",
                justifyContent: "center",
            }}
        >
            <Card sx={{ maxWidth: 512, p: 3 }}>
                <CardMedia
                    component="img"
                    height="60%"
                    image={errorImage}
                    alt="Server Error"
                    sx={{ p: 2 }}
                />
                <CardContent>
                    <PageTitle2 sx={{ textAlign: "center" }}>
                        {t("backend.error_title")}
                    </PageTitle2>
                    <Text sx={{ textAlign: "center" }}>
                        {t("backend.error_text")}
                    </Text>
                </CardContent>
                <CardActions sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleRetry}
                    >
                        {t("backend.error_retry")}
                    </Button>
                </CardActions>
            </Card>
        </Box>
    );
};

export default BackendError;
