import React from "react";

import { FormControlLabel } from "@mui/material";
import { Checkbox as MuiCheckbox } from "@mui/material";

const Checkbox = (props) => {
    // Destructure props and set defaults
    const {
        name,
        color = "secondary",
        size = "small",
        label = "Label goes here",
        value,
        onChange,
        ...rest
    } = props;

    // Checkbox does not return an e.target.value property
    // convert it before handing over to onChange
    const convertChecked = (name, value) => ({
        target: {
            name,
            value,
        },
    });

    return (
        <FormControlLabel
            control={
                <MuiCheckbox
                    name={name}
                    checked={value}
                    onChange={(e) =>
                        onChange(convertChecked(name, e.target.checked))
                    }
                    color={color}
                    size={size}
                    {...rest}
                />
            }
            label={label}
        />
    );
};

export default Checkbox;
