import React from "react";
import { useSelector } from "react-redux";
import { Switch } from "react-router-dom";

import { routeComponents as components } from "./routes";
import { getRouteConfig } from "../selectors/wpContent";

import AppRoute from "./components/AppRoute";

// get route config from state and create array of objects to render <Route />
const getRoutes = (routeConfig, routes = []) => {
    routeConfig.forEach((route) => {
        if (route.items) getRoutes(route.items, routes);
        if (route.type === "link")
            routes.push({
                path: route.to,
                name: route.label,
                isPrivate: route.isPrivate,
                component: components[route.component], // reference to Component
            });
    });
    return routes;
};

// Create routes for react-router-dom
const AppRoutes = () => {
    const routeConfig = useSelector((state) => getRouteConfig(state));
    const routes = getRoutes(routeConfig);

    // exact allows any order of routes in routeConfig JSON
    return (
        <Switch>
            {routes.map((route, key) => {
                return <AppRoute exact {...route} key={key} />;
            })}
        </Switch>
    );
};

export default AppRoutes;
