import React from "react";

import parse from "html-react-parser"; // Rendering the HTML string, will be vulnerable to cross-site scripting attacks (XSS).

import { FaUserTie } from "react-icons/fa";

const Summary = ({ summary }) => {
    const content = (
        <>
            <h2 className="section-title">
                <span className="icon-holder">
                    <FaUserTie />
                </span>
                {summary.title}
            </h2>
            <div className="summary">{parse(summary.content)}</div>
        </>
    );
    return <div className="section summary-section">{content}</div>;
};

export default Summary;
