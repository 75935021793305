import React from "react";
import { useTranslation } from "react-i18next";

import { Input, InputAdornment, IconButton } from "@mui/material";
import { Icons } from "../../mui/icons";

const Searchbar = ({ query, onQueryChange, onQueryClear, width = "200px" }) => {
    // i18n translations
    const { t } = useTranslation();

    const showClearIcon = query.length > 0;

    return (
        <Input
            id="search"
            type="text"
            // className={classes.root}
            value={query}
            onChange={(event) => onQueryChange(event.target.value)}
            sx={{ width: width }}
            startAdornment={
                <InputAdornment position="start">
                    <Icons.Search />
                </InputAdornment>
            }
            endAdornment={
                showClearIcon && (
                    <InputAdornment position="end">
                        <IconButton
                            color="accent"
                            size="small"
                            onClick={onQueryClear}
                        >
                            <Icons.Clear />
                        </IconButton>
                    </InputAdornment>
                )
            }
            placeholder={t("search.placeholder")}
        />
    );
};

export default Searchbar;
