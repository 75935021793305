import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import validator from "validator";
import { toast } from "react-toastify";

import { Box, CircularProgress } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
// import { makeStyles } from "@mui/styles";

import Form from "../../mui/controls/Form";
import Controls from "../../mui/controls/Controls";
import { useForm } from "../../hooks/useForm";

import { publicAPI } from "../../configs/axios-wordpress";

// Customized styles
// const useStyles = makeStyles((theme) => ({
//     root: {
//     },
// }));

/*
 * Contact form
 */
export const ContactForm = ({ toggleOpen }) => {
    const { t } = useTranslation();

    // Initial form control values
    const initValues = {
        subject: "",
        message: "",
        email: "",
    };

    // Custom hook for managing forms
    const {
        values,
        onChange,
        errors,
        setErrors,
        setInitValues,
        submitting,
        setSubmitting,
    } = useForm({
        ...initValues,
    });

    // Toast notifications
    const notifySuccess = () =>
        toast.success(t("forms.thank_you_for_your_message"));
    const notifyFailure = () =>
        toast.error(t("forms.sending_contact_form_message_failed"));

    // Validate each form control, return true if no errors
    const validate = () => {
        const form = {}; // temp object to store error messages
        // Check multiple conditions and set appropriate error message
        switch (true) {
            case values.email.length === 0:
                form["email"] = t("forms.enter_email_address");
                break;
            case !validator.isEmail(values.email):
                form["email"] = t("forms.no_valid_email");
                break;
            default:
                form["email"] = "";
                break;
        }
        switch (true) {
            case values.message.length === 0:
                form["message"] = t("forms.required");
                break;
            case values.message.length < 5:
                form["message"] = t("forms.be_more_detailed");
                break;
            default:
                form["message"] = "";
                break;
        }
        form["subject"] =
            values.subject.length === 0 ? t("forms.required") : "";
        // Check if all values in errors Object are empty
        const result = Object.values(form).every((val) => val === "");
        if (result) {
            setErrors(null); // delete previous errors, if any
            return true; // form data valid
        } else {
            setErrors({ ...form }); // set error message per form control
            setSubmitting(false); // re-enable submit button
            return false; // form data invalid
        }
    };

    // Handle cancel
    const handleCancel = () => {
        setInitValues();
        setErrors({});
        toggleOpen();
    };

    // Handle form submit
    const handleSubmit = async (e) => {
        e.preventDefault(); // prevent page re-load
        setSubmitting(true);
        if (validate()) {
            try {
                // create Message object
                const wpMessage = {
                    from: values.email,
                    subject: values.subject,
                    content: values.message,
                    date: moment().valueOf(), // current timestamp
                };
                await publicAPI.post("/store_message", wpMessage);
                setSubmitting(false);
                // Display toast message
                notifySuccess();
                // Reset controls
                setInitValues();
                // Update redux store
                toggleOpen();
            } catch (error) {
                // Display failure toast
                notifyFailure();
                // Update redux store
                toggleOpen();
            }
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Controls.Input
                name="subject"
                margin="normal"
                required
                fullWidth
                label={t("forms.subject")}
                onFocus={() => setErrors({})}
                onChange={onChange}
                value={values.subject}
                error={!!errors?.subject}
                helperText={errors && errors.subject}
            />
            <Controls.Input
                name="message"
                multiline={true}
                rows={3}
                margin="normal"
                required
                fullWidth
                label={t("forms.message")}
                onFocus={() => setErrors({})}
                onChange={onChange}
                value={values.message}
                error={!!errors?.message}
                helperText={errors && errors.message}
            />
            <Controls.Input
                id="email"
                name="email"
                margin="normal"
                required
                fullWidth
                label={t("forms.email")}
                onFocus={() => setErrors({})}
                autoComplete="email"
                autoFocus
                onChange={onChange}
                value={values.email}
                error={!!errors?.email}
                helperText={errors && errors.email}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Controls.Button
                    variant="outlined"
                    onClick={handleCancel}
                    sx={{ mt: 3, mb: 2 }}
                    startIcon={<DeleteIcon />}
                >
                    {t("forms.cancel")}
                </Controls.Button>
                <Controls.Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, ml: 2, mb: 2 }}
                    disabled={submitting}
                    startIcon={
                        submitting ? (
                            <CircularProgress color="secondary" size={20} />
                        ) : (
                            <SendIcon />
                        )
                    }
                >
                    {t("forms.submit")}
                </Controls.Button>
            </Box>
        </Form>
    );
};
