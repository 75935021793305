// Action creators used by dispatch calls to manage loading states

import { START_LOADING, END_LOADING } from "./types";

export const startLoading = () => ({
    type: START_LOADING,
});

export const endLoading = () => ({
    type: END_LOADING,
});
