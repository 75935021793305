import React from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser"; // Rendering the HTML string, will be vulnerable to cross-site scripting attacks (XSS).
import { Accordion } from "../../../../mui/surfaces/Accordion";
import { AccordionSummary, AccordionDetails } from "@mui/material";
import { Icons } from "../../../../mui/icons";
import { PageTitle2 } from "../../../../mui/typography/Typography";

const WhyMe = ({ whyme }) => {
    // enable i18n translations
    const { t } = useTranslation();

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<Icons.ExpandMore />}
                sx={{ marginBottom: 0 }}
            >
                <PageTitle2 sx={{ paddingBottom: 0 }}>
                    {t("cv.whyme")}
                </PageTitle2>
            </AccordionSummary>
            <AccordionDetails className="item">{parse(whyme)}</AccordionDetails>
        </Accordion>
    );
};

export default WhyMe;
