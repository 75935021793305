import React from "react";

import { useTranslation } from "react-i18next";

import { routeComponents } from "../../routes/routes";

import { useTabs } from "../../hooks/useTabs";

import { Grid } from "@mui/material";
import { PageTitle } from "../../mui/typography/Typography";

const FamilyFriends = (props) => {
    const { t } = useTranslation();

    // Get last path segment from URL, e.g. /ff/gallery
    const path = props.match.path.split("/")[2];

    // Capitalize path to be used as key for routeComponents object
    const component = path.charAt(0).toUpperCase() + path.slice(1);

    // Use custom hook for navigation tabs
    const [tabHeader] = useTabs(props);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <PageTitle>{t("navigation.family_friends")}</PageTitle>
            </Grid>
            <Grid item xs={12}>
                {tabHeader}
            </Grid>
            <Grid item xs={12}>
                {/* Render sub component content here */}
                {React.createElement(routeComponents[component], { props })}
            </Grid>
        </Grid>
    );
};

export default FamilyFriends;
