// Thunk functions for asynchronous tasks that dispatch actions afterwards
import { authAPI, userAPI } from "../configs/axios-wordpress";
import {
    startGetPublicWpContent,
    startGetPrivateWpContent,
} from "../thunks/wpContent";
import { endLoading } from "../actions/loading";
import { clearPrivateWpContent } from "../actions/wpContent";
import {
    loginUserSuccess,
    loginUserFailure,
    logoutUser,
    setLanguage,
    setRoles,
} from "../actions/user";
import { getUserIsLoggedIn } from "../selectors/user";
import { setSessionCookie } from "../services/handleCookies";

// Set session cookie, get user roles and get private WP content
export const startLoginUser = (profile) => {
    // console.log("profile ", profile);
    return (dispatch, getState) => {
        try {
            // Set cookie with WP user account data and token
            setSessionCookie("user", profile);
            // Update user's state in redux store
            dispatch(loginUserSuccess(profile));
            // Get users WP role
            dispatch(startGetUserRoles(profile.id));
            // Fetch private WP content and update state
            dispatch(startGetPrivateWpContent(profile));
            // Hide loading spinner
            dispatch(endLoading());
        } catch (error) {
            // Update user's state in redux store
            dispatch(loginUserFailure(error));
            dispatch(endLoading());
        }
    };
};

// Clear the auth cookie with token and update persisted content
// TODO: write reducer that clears private content
export const startLogoutUser = () => {
    return (dispatch, getState) => {
        // access to redux state object
        const state = getState();
        // use selector to get user's state
        const isLoggedIn = getUserIsLoggedIn(state);
        // user is logged in
        if (isLoggedIn) {
            // remove cookie with JWT token
            sessionStorage.removeItem("user");
            // update users isLoggedIn state
            dispatch(logoutUser());
            // remove private WP content from state
            dispatch(clearPrivateWpContent());
            // return Promise, e.g. to display success toast
            return Promise.resolve(true);
        }
        // return Promise, e.g. to display failure toast
        return Promise.resolve(false);
    };
};

// Check if user has valid auth token
export const startCheckUserToken = () => {
    return async (dispatch, getState) => {
        try {
            // query JWT Auth API with a potential token as Authorisation header
            const token = await authAPI.post("/token/validate");
            // if a valid token was sent and confirmed
            if (token.data.success) {
                try {
                    // read data from local storage
                    const data = JSON.parse(sessionStorage.getItem("user"));
                    // dispatch login action with user data as payload
                    dispatch(loginUserSuccess(data));
                    // fetch private content from WP API
                    dispatch(startGetPrivateWpContent());
                } catch (error) {
                    console.log(
                        "Parsing of user data from local storage failed: ",
                        error
                    );
                }
            }
            // fetch public content from WP API
            dispatch(startGetPublicWpContent());
        } catch (error) {
            console.log(
                "JWT Auth API request to validate token failed: ",
                error
            );
            // try to load public content anyway
            dispatch(startGetPublicWpContent());
        }
    };
};

// Check which language the i18n LanguageDetector has set and update state accordingly at initial load
export const startSetUserLanguage = (newLang = null) => {
    return (dispatch) => {
        try {
            // get lang from localStorage (initial load) or argument (user toggels lang)
            const lang = newLang ? newLang : localStorage.getItem("i18nextLng");
            // update state accordingly, default is "en-US" if no match
            switch (lang) {
                case "de":
                    dispatch(setLanguage("de"));
                    localStorage.setItem("i18nextLng", "de");
                    break;
                default:
                    dispatch(setLanguage("en"));
                    localStorage.setItem("i18nextLng", "en");
                    break;
            }
        } catch (error) {
            console.log(
                "Error while getting / setting i18nextLng from / to localStorage: ",
                error
            );
        }
    };
};

// Returns array of user's roles in wordpress
export const startGetUserRoles = (userId) => {
    return async (dispatch) => {
        try {
            if (!userId) return;
            const user = await userAPI.get(`/${userId}`);
            dispatch(setRoles(user.data.roles));
        } catch (error) {
            console.log(
                `Could not get user profile for id: ${userId}, ${error}`
            );
        }
    };
};
