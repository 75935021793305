import React from "react";
import style from "./LoadingSpinner.module.scss";

const LoadingSpinner = () => {
    return (
        <div className={style.loadingSpinner}>
            <div className={style.loader}>
                <div></div>
            </div>
        </div>
    );
};

export default LoadingSpinner;
