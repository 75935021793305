import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

// Page Title
export const PageTitle = styled((props) => (
    <Typography
        {...props}
        color="accent.main"
        component="div"
        variant={props.variant || "h3"}
    />
))(({ theme }) => ({
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    position: "relative",
    // Decoration left side
    "&::after": {
        content: "''",
        display: "block",
        position: "absolute",
        bottom: "20%",
        left: "0",
        width: "20%",
        height: "1rem",
        border: "3px solid",
        borderRadius: "3px",
        borderTopColor: "transparent",
        borderRightColor: "transparent",
        borderBottomColor: theme.palette.accent.main,
        borderLeftColor: theme.palette.accent.main,
    },
}));

// Page Title Alt
export const PageTitle2 = styled((props) => (
    <Typography
        {...props}
        color="accent.main"
        component="div"
        variant={props.variant || "h5"}
    />
))(({ theme }) => ({
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    position: "relative",
}));

// Page Text
export const Text = styled((props) => (
    <Typography
        {...props}
        variant="body1"
        component="div"
        color={props.color || "palette.text.primary"}
    />
))(({ theme }) => ({
    "& ul": {
        lineHeight: "1.8rem",
        margin: 0,
        padding: "0.5rem 0",
    },
    "& li": {
        listStyle: "none",
        position: "relative",
        padding: "0 0 0 20px",
    },
    // Unsorted list icons
    "& li::before": {
        content: "''",
        position: "absolute",
        left: 0,
        top: "8px",
        width: 0,
        height: 0,
        borderLeft: "5px solid transparent",
        borderRight: "5px solid transparent",
        borderBottom: `10px solid ${theme.palette.grey[600]}`,
        transform: "rotate(90deg)",
    },
}));
