import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Button,
} from "@mui/material";

import { PageTitle2, Text } from "../../mui/typography/Typography";

import errorImage from "../../assets/images/404-not-found.png";

const PageNotFound = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const onClick = () => {
        history.push("/home");
    };

    return (
        <Box sx={{ display: "grid", placeContent: "center" }}>
            <Card sx={{ maxWidth: 512 }}>
                <CardMedia
                    component="img"
                    height="60%"
                    image={errorImage}
                    alt="404 Not Found"
                    sx={{ p: 2 }}
                />
                <CardContent>
                    <PageTitle2 sx={{ textAlign: "center" }}>
                        {t("navigation.not_found_title")}
                    </PageTitle2>
                    <Text sx={{ textAlign: "center" }}>
                        {t("navigation.not_found_text")}
                    </Text>
                </CardContent>
                <CardActions sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onClick}
                    >
                        {t("navigation.back")}
                    </Button>
                </CardActions>
            </Card>
        </Box>
    );
};

export default PageNotFound;
