import store from "../store/store";
import { privateAPI } from "../configs/axios-wordpress";
import { getUserProfile } from "../selectors/user";

// Change password for WP user
const changePassword = async (newPassword) => {
    const state = store.getState();
    const userProfile = getUserProfile(state);
    const userId = userProfile.id;
    try {
        const id = await privateAPI.post("/users/change_password", {
            newPassword,
            userId,
        });
        return id;
    } catch (error) {
        return error;
    }
};

export { changePassword };
