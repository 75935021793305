import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { Grid, Box, Paper } from "@mui/material";

import { PageTitle2 } from "../../mui/typography/Typography";

import { LoginForm, ForgotPasswordForm } from "./Forms";

// Login Page
const Login = (props) => {
    // Differentiate Sceanrios: "login" vs. "reset"
    const [isLogin, setIsLogin] = useState(true);

    const { t } = useTranslation();

    const handleResetPassword = () => setIsLogin(false);

    const handleLogin = () => setIsLogin(true);

    // Get path, e.g. /ff/dashboard, the user wanted to visit
    const referer = props.location.state
        ? props.location.state.from.pathname
        : "/home";

    return (
        <Grid
            container
            sx={{
                height: "auto",
                maxWidth: "40rem",
            }}
        >
            {/* Image */}
            <Grid
                item
                xs={false}
                sm={5}
                sx={{
                    backgroundImage: "url('images/family_friends.jpg')",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: "transparent",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            />
            {/* Form */}
            <Grid item xs={12} sm={7} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 4,
                        mx: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            m: 1,
                        }}
                    >
                        <PageTitle2
                            component="div"
                            variant="h6"
                            textAlign="center"
                        >
                            {isLogin
                                ? t("forms.family_friends_access")
                                : t("forms.reset_password")}
                        </PageTitle2>
                    </Box>
                    {isLogin ? (
                        <LoginForm
                            referer={referer}
                            handleResetPassword={handleResetPassword}
                        />
                    ) : (
                        <ForgotPasswordForm handleLogin={handleLogin} />
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};

export default Login;
