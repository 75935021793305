import { useState } from "react";

// Custom hook for form controls
export const useForm = (initValues) => {
    // Store inital form values for reset/cancel action
    const origValues = {
        ...initValues,
    };
    // Store actual from values
    const [values, setValues] = useState({
        ...initValues,
    });
    // Store error string per form control value
    const [errors, setErrors] = useState(null);

    // Disable submit button during async processing
    const [submitting, setSubmitting] = useState(false);

    // Update single form control value on change
    const onChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    // Reset form to its initial values
    const setInitValues = () => {
        setValues(origValues);
    };

    return {
        values,
        onChange,
        setInitValues,
        errors,
        setErrors,
        submitting,
        setSubmitting,
    };
};
