// Selectors for wpContent slice

import { getUserLanguage } from "./user";

export const getPages = (state) => state.wpContent.pages;
export const getConfigs = (state) => state.wpContent.configs;
export const getRouteConfig = (state) => state.wpContent.configs.routes.routes;
export const getMessages = (state) => state.wpContent.messages;
export const getWpContentError = (state) => state.wpContent.error;
export const getMediaLibraryItems = (state) => state.wpContent.media;

// single page by Page ID
export const getPageById = (state, id) => {
    const pages = getPages(state);
    const page = pages.find((page) => page.id === parseInt(id));
    return page ? createAcfProperty(page) : null;
};

// single page by HP-Section considering users language
export const getPageBySection = (state, section) => {
    const lang = getUserLanguage(state).toLowerCase();
    const pages = getPages(state);
    const page = pages.find(
        (page) =>
            page.categories.toLowerCase().includes(lang) &&
            page.categories.toLowerCase().includes(section.toLowerCase())
    );
    return page ? createAcfProperty(page) : null;
};

// array of portfolio pages considering users language
export const getPortfolioPages = (state) => {
    const lang = getUserLanguage(state).toLowerCase();
    const pages = getPages(state);
    return pages
        ? pages.filter(
              (page) =>
                  page.categories.toLowerCase().includes("portfolio") &&
                  page.categories.toLowerCase().includes(lang.toLowerCase())
          )
        : null;
};

/*
 * Helper functions
 */

const createAcfProperty = (page) => {
    if (page.acf && page.acf.keyValues) {
        const kv = page.acf.keyValues.reduce(
            (acc, cur) => ({ ...acc, [cur.key]: cur.value }),
            {}
        );
        // add acfKeyValue property to existing page properties
        return { ...page, acfKeyValues: kv };
    }
    // return unmodified page object
    return page;
};
