import React from "react";

const Education = ({ education }) => {
    const entry = education.map((item) => {
        return (
            <div className="item" key={item.degree}>
                <p className="heading">{item.degree}</p>
                <p className="meta">{item.location}</p>
                <p className="time">{item.time}</p>
            </div>
        );
    });

    return (
        <div className="sidebar-container">
            <div className="title">Education</div>
            {entry}
        </div>
    );
};

export default Education;
