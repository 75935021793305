// Action creators used by dispatch calls to manage wpContent state in store
import {
    SET_PUBLIC_WP_CONTENT,
    SET_PRIVATE_WP_CONTENT,
    CLEAR_PRIVATE_WP_CONTENT,
    SET_WP_CONTENT_ERROR,
} from "./types";

export const setPublicWpContent = (payload = []) => ({
    type: SET_PUBLIC_WP_CONTENT,
    payload,
});

export const setPrivateWpContent = (payload = []) => ({
    type: SET_PRIVATE_WP_CONTENT,
    payload,
});

export const clearPrivateWpContent = () => ({
    type: CLEAR_PRIVATE_WP_CONTENT,
});

export const setWpContentError = (payload = "") => ({
    type: SET_WP_CONTENT_ERROR,
    payload,
});
