import React, { useState } from "react";
import { useSelector } from "react-redux";
import { usePage } from "../../hooks/usePage";

import { getPageById, getPortfolioPages } from "../../selectors/wpContent";

import Searchbar from "../../components/Search/Searchbar";
import PortfolioList from "./PortfolioList";

import { Grid } from "@mui/material";
import { PageTitle, Text } from "../../mui/typography/Typography";

const Portfolio = () => {
    // Get only current user language from hook
    const [, , lang] = usePage();

    // Page Ids of both portfolio landing pages
    const pageId = {
        "en-US": 982,
        "de-DE": 984,
    };

    // Get landing page for current language
    const portfolioPage = useSelector((state) =>
        getPageById(state, pageId[lang])
    );

    // Deconstruct relevant fields
    const { title, text1, text2 } = portfolioPage.acfKeyValues;

    // Get all pages with category "portfolio" in current language
    const portfolioItems = useSelector((state) => getPortfolioPages(state));

    // Remove the two landing pages from array
    const listItems = portfolioItems.filter(
        (item) => item.id !== pageId["en-US"] && item.id !== pageId["de-DE"]
    );

    // State of searchbox value
    const [query, setQuery] = useState("");

    // Handle searchbox changes
    const onQueryChange = (newQuery) => {
        setQuery(newQuery);
    };
    // Handle searchbox clear click
    const onQueryClear = () => {
        setQuery("");
    };

    // Filter portfolio pages array by searchbox query
    let filteredListItems = [...listItems];
    if (query.length > 2) {
        filteredListItems = listItems.filter((page) => {
            return (
                page.title.toLowerCase().includes(query.toLocaleLowerCase()) ||
                page.excerpt
                    .toLowerCase()
                    .includes(query.toLocaleLowerCase()) ||
                page.content.toLowerCase().includes(query.toLocaleLowerCase())
            );
        });
    }

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <PageTitle>{title}</PageTitle>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Text>{text1}</Text>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Text>{text2}</Text>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Searchbar
                        query={query}
                        onQueryChange={onQueryChange}
                        onQueryClear={onQueryClear}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <PortfolioList
                            listItems={filteredListItems}
                            setQuery={setQuery}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Portfolio;
