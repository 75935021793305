import React from "react";

import { Grid, Box, Typography, useTheme, Button } from "@mui/material";

import { usePage } from "../../hooks/usePage";

import ActionButton from "../../components/ActionButton/ActionButton";
import TypingCarousel from "../../components/TypingCarousel/TypingCarousel";
import Qrcode from "../../components/Qrcode/Qrcode";

const Home = () => {
  const [page, t] = usePage("home");
  const theme = useTheme();

  // Destructure content
  const {
    greeting = "Hello",
    firstname = "John",
    lastname = "Doe",
    carouselString = "I love",
    carouselContent = "coding. ; drinking.",
  } = page.acfKeyValues;

  // Format TypingCarousel content
  const carouselItems = carouselContent.split(";").map((item) => item.trim());

  // Action button links
  const [action1, action2] = page.acf.links;

  // Home page image
  const imgUrl = page.acf.images[0]?.sizes.large;

  return (
    <Grid container spacing={1} sx={{ pt: { xs: 22, sm: 0 } }}>
      {/* Name & Typing Carousel */}
      <Grid item xs={12} sm={6}>
        <Typography component="div" variant="h4">
          {greeting}
        </Typography>
        <Typography
          component="div"
          variant="h2"
          sx={{
            fontWeight: theme.typography.fontWeightBold,
            padding: "1rem 0",
          }}
        >
          {firstname}
          <Typography
            color="accent.main"
            component="span"
            fontWeight={theme.typography.fontWeightBold}
            variant="h2"
            sx={{ marginLeft: "0.2rem" }}
          >
            {lastname}
          </Typography>
        </Typography>
        <Box
          sx={{
            "& .fixed, .dynamic": {
              fontSize: "1.2rem",
              fontWeight: theme.typography.fontWeightBold,
            },
            "& .dynamic": {
              color: theme.palette.accent.main,
            },
            "& .cursor": {
              borderRight: `0.2rem solid ${theme.palette.text.secondary}`,
              paddingRight: "0.3rem",
            },
          }}
        >
          <TypingCarousel
            txtFixed={carouselString}
            txtCarousel={carouselItems}
            period="2000"
          />
        </Box>
        <Box
          sx={{
            paddingTop: "2rem",
          }}
        >
          <Button
            sx={{ padding: 0, justifyContent: "left" }}
            onClick={() => {
              window.open("https://www.linkedin.com/in/steffen-fidelius/");
              return null;
            }}
          >
            <img
              src="images/li-logo1.png"
              alt="LinkedIn"
              style={{ maxHeight: "2rem" }}
            />
          </Button>
          <Button
            sx={{ padding: 0, justifyContent: "left" }}
            onClick={() => {
              window.open("https://www.xing.com/profile/Steffen_Fidelius");
              return null;
            }}
          >
            <img
              src="images/xing-logo.png"
              alt="Xing"
              style={{ maxHeight: "2rem" }}
            />
          </Button>
        </Box>
      </Grid>
      {/* Image */}
      <Grid item sm={6}>
        {imgUrl && (
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "block",
              },
              "& img": { width: "100%" },
            }}
          >
            <img src={imgUrl} alt="bio" />
          </Box>
        )}
      </Grid>
      {/* Action Buttons */}
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            paddingTop: "1rem",
          }}
        >
          <div style={{ minWidth: "10rem", maxWidth: "13rem" }}>
            <ActionButton href={action1.link.url} label={action1.link.title} />
          </div>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div style={{ minWidth: "10rem", maxWidth: "13rem" }}>
            <ActionButton href={action2.link.url} label={action2.link.title} />
          </div>
        </Box>
      </Grid>
      {/* QR Code */}
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Qrcode
            url="https://www.fidelius-online.de"
            size={64}
            caption={t("misc.qr_caption")}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Home;
