import React, { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
    Grid,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    Typography,
} from "@mui/material";

import noMedia from "../../assets/images/no-media.jpg";

const PortfolioList = ({ listItems, setQuery }) => {
    // i18n translations
    const { t } = useTranslation();

    // Store reference to setTimeout function
    let timer = useRef();

    // Clear setTimeout if PortfolioList component unmounts
    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    // Render cards if array has entries
    if (listItems.length) {
        return listItems.map((item) => {
            // Deconstruct Card content
            const cardContent = {
                image: item.featuredImage.medium || noMedia,
                title: item.title,
                excerpt: item.excerpt,
                path: `/portfolio/${item.id}`,
            };
            return (
                <Grid item xs={12} sm={6} md={4} key={item.title}>
                    <MuiCard {...cardContent} />
                </Grid>
            );
        });
        // Else show message and delete query string
    } else {
        timer.current = setTimeout(() => setQuery(""), 5000);
        return (
            <Grid item xs={12}>
                <Typography color="info.main" variant="subtitle1">
                    {t("search.no_match")}
                </Typography>
            </Grid>
        );
    }
};

// MUI Portfolio Card component
const MuiCard = ({ image, title, excerpt, path }) => {
    const history = useHistory();
    return (
        <Card
            sx={{
                height: "100%",
                "&:hover": { transform: "scale(1.02)", boxShadow: 3 },
            }}
        >
            <CardActionArea onClick={() => history.push(path)}>
                <CardMedia
                    component="img"
                    image={image}
                    sx={{ ".MuiCardMedia-img": { height: "8rem" } }}
                />
                <CardContent sx={{}}>
                    <Typography gutterBottom variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {excerpt}{" "}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default PortfolioList;
