import React from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { toggleContactForm } from "../../actions/visibility";

import { Global } from "@emotion/react";

import {
    styled,
    IconButton,
    Container as MuiContainer,
    Box,
    SwipeableDrawer,
    // Skeleton,
    useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";

import { Text } from "../../mui/typography/Typography";

import Content from "./Content";

import { Icons } from "../../mui/icons";

// Vsisible Drawer space on page bottom in closed state
export const drawerBleeding = 24;

// Wrapper around Footer component
const Container = styled("div")(({ theme }) => ({
    // backgroundColor: theme.palette.background.default,
    height: "100%",
    display: "flex",
    justifyContent: "center",
}));

// Warpper around Drawer and Content section
const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: grey[200],
}));

// Handle for mobile view
const PullerMobile = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
    borderRadius: 3,
    height: 6,
    left: "calc(50% - 15px)",
    position: "absolute",
    top: 8,
    width: 30,
}));

// Button for desktop view
const ButtonDesktop = styled("div")(({ theme }) => ({
    bottom: "10px",
    position: "fixed",
    zIndex: 2000, // zIndex of PrivateSwipeArea-root is 1199
    "& .MuiSvgIcon-root": {
        color: theme.palette.secondary.main,
        height: "2rem",
        width: "2rem",
    },
}));

function Footer(props) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const dispatch = useDispatch();

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const openContactForm = () => dispatch(toggleContactForm());

    const currentYear = moment().format("YYYY");

    return (
        <Container>
            {/* Swipeable Drawer styles */}
            <Global
                styles={{
                    ".MuiDrawer-root .MuiPaper-root": {
                        xs: {
                            maxHeight: `calc(75% - ${drawerBleeding}px)`,
                        },
                        sm: {
                            maxHeight: `calc(33% - ${drawerBleeding}px)`,
                        },
                        margin: "auto", // center Footer div
                        overflow: "visible",
                        maxWidth: theme.breakpoints.values.md,
                    },
                }}
            />
            {/* Open Footer Button - Desktop view only */}
            <ButtonDesktop
                sx={{
                    display: open ? "none" : { xs: "none", sm: "block" },
                }}
            >
                <IconButton onClick={toggleDrawer(true)}>
                    <Icons.ExpandLess />
                </IconButton>
            </ButtonDesktop>
            {/* The Drawer component with Footer content */}
            <SwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                {/* Open Footer Handle - Mobile view only */}
                <StyledBox
                    sx={{
                        backgroundColor: theme.palette.secondary.main,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        height: drawerBleeding,
                        left: 0,
                        position: "absolute",
                        right: 0,
                        top: -drawerBleeding,
                        visibility: "visible",
                    }}
                >
                    <Box sx={{ textAlign: "right" }}>
                        <Text
                            sx={{
                                color: theme.palette.background.default,
                                fontSize: "0.8rem",
                                paddingRight: "0.5rem",
                            }}
                        >
                            Steffen Fidelius &copy; {currentYear}
                        </Text>
                    </Box>
                    <Box
                        sx={{
                            display: {
                                xs: "block",
                                sm: "none",
                            },
                        }}
                    >
                        <PullerMobile />
                    </Box>
                </StyledBox>

                {/* Footer Content */}
                <StyledBox
                    sx={{
                        height: "100%",
                        overflow: "auto",
                        paddingTop: theme.spacing(2),
                    }}
                >
                    <MuiContainer maxWidth="md" sx={{ height: "100%" }}>
                        <Content
                            toggleDrawer={toggleDrawer}
                            openContactForm={openContactForm}
                        />
                        {/* <Skeleton variant="rectangular" height="100%" /> */}
                    </MuiContainer>
                </StyledBox>
            </SwipeableDrawer>
        </Container>
    );
}

export default Footer;
