// MUI icons used troughout the App

import {
    Language,
    Brightness4,
    Home,
    AutoGraph,
    AssignmentInd,
    ContactSupport,
    PeopleAlt,
    ManageAccounts,
    PersonOutlineOutlined,
    Login,
    Logout,
    Person,
    Collections,
    FileDownload,
    Speed,
    AssignmentTurnedIn,
    Policy,
    Gavel,
    MoreVert,
    Menu,
    ExpandMore,
    ExpandLess,
    LocationOn,
    PhoneAndroid,
    AlternateEmail,
    Search,
    Clear,
    AccessTime,
    Edit,
    Close,
    QuestionMark,
    Toys,
} from "@mui/icons-material";

// Mapping object for json attributes from WP config data to MUI icons
export const Icons = {
    Home, // Homepage
    AutoGraph, // Portfolio
    Language, // Language Setting
    Brightness4, // Theme Setting
    AssignmentInd, // CV
    ContactSupport, // About
    PeopleAlt, // Family & Friends
    ManageAccounts, // Account/Profile
    Person, // Account/Profile alt
    PersonOutlineOutlined, // Account/Profile alt
    Collections, // Gallery
    FileDownload, // Downloads
    Speed, // Dashboard
    AssignmentTurnedIn, // Projects
    MoreVert, // More
    Policy, // Data Protection
    Gavel, // Legal Statement
    Login, // Login
    Logout, // Logout
    Menu, // Mobile Menu Icon
    ExpandMore, // Dropdown Mobile View
    ExpandLess, // Dropdown Mobile View
    LocationOn, // Location
    PhoneAndroid, // Phone
    AlternateEmail, // Email
    Search, // Search Field Portfolio
    Clear, // Clear Icon e.g. in Search Field
    AccessTime, // Reading Time
    Edit, // Last Edited
    Close, // Cross
    QuestionMark, // Placeholder
    Toys, // Playground
};
