import React from "react";

const Languages = ({ languages }) => {
    const entry = languages.map((item) => {
        return (
            <li key={item.language}>
                <p className="heading">
                    {item.language}
                    <span className="meta" style={{ marginLeft: "3px" }}>
                        ({item.level})
                    </span>
                </p>
            </li>
        );
    });

    return (
        <div className="sidebar-container">
            <h2 className="title">Languages</h2>
            <div className="item">
                <ul className="list-unstyled">{entry}</ul>
            </div>
        </div>
    );
};

export default Languages;
