import React from "react";
import { useSelector } from "react-redux";
import { getPageBySection } from "../../selectors/wpContent";

import Sidebar from "./Sidebar/Sidebar";
import Main from "./Main/Main";

import { Grid } from "@mui/material";
import { PageTitle } from "../../mui/typography/Typography";

const Cv = () => {
    const page = useSelector((state) => getPageBySection(state, "Cv"));
    const data = page.acf;

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <PageTitle>{page.title}</PageTitle>
            </Grid>
            <Grid item xs={12}>
                <Grid
                    container
                    // Show Sidebar first on mobile screens
                    sx={{
                        flexDirection: { xs: "column-reverse", sm: "row" },
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        sm={7}
                        sx={{ paddingTop: { xs: "3rem", sm: 0 } }}
                    >
                        <Main {...data} />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Sidebar {...data} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Cv;
