import { publicAPI } from "../configs/axios-wordpress";

// To dispatch an API error
import store from "../store/store";

import { setWpContentError } from "../actions/wpContent";

// Returns response objects for each URI
export const fetchApiData = async (uris = [], api = publicAPI) => {
    try {
        if (!uris.length) {
            throw new Error();
        }
        // create array of promises
        const promises = uris.map((uri) => api.get(`/${uri}`));
        // bundle single promises
        const allPromise = Promise.all(promises);
        // fetch API data
        const values = await allPromise;
        // clear any previous error we might had
        store.dispatch(setWpContentError(null));
        // return array with response objects
        return values;
    } catch (error) {
        store.dispatch(
            setWpContentError(`Can't fetch content from WP API! ${error}`)
        );
        return error;
    }
};
