import React from "react";
import { Stack, Button } from "@mui/material";

const Badges = ({ badges }) => {
  // const entry = badges.map((item) => {
  //     return (
  //         <li key={item.interest}>
  //             <p className="heading" style={{ marginBottom: "10px" }}>
  //                 {item.interest}
  //             </p>{" "}
  //         </li>
  //     );
  // });

  return (
    <div className="sidebar-container">
      <h2 className="title">Badges/Certs</h2>

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Button
          onClick={() => {
            window.open(
              "https://google.accredible.com/94b68fde-8990-450a-b299-7c2e8451f837"
            );
            return null;
          }}
        >
          <img
            src="images/gcp-pde-badge.png"
            alt="GCP Professional Data Engineer"
            style={{ maxWidth: "100%" }}
          />
        </Button>
        <Button
          onClick={() => {
            window.open(
              "https://confirm.udacity.com/e/94d6afe4-98b9-11ed-b26d-47e14b692e8a"
            );
            return null;
          }}
        >
          <img
            src="images/udemy-ai-programming-nanodegree.png"
            alt="AI Programming with Python"
            style={{ maxWidth: "100%" }}
          />
        </Button>
      </Stack>
    </div>
  );
};

export default Badges;
