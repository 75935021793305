import React from "react";
import parse from "html-react-parser";

import { Grid } from "@mui/material";

import { usePage } from "../../hooks/usePage";

import { PageTitle, PageTitle2, Text } from "../../mui/typography/Typography";

import ProgressBar from "../../components/ProgessBar/ProgressBar";
import IconCarousel from "../../components/IconCarousel/IconCarousel";

const About = () => {
    const [page] = usePage("about");

    const { heading1, text1, heading2, text2, ...rest } = page.acfKeyValues;

    // returns array of strings containing ["skillTitle, level", ...]
    const filterSkills = () => {
        return Object.entries(rest)
            .filter((entry) => entry[0].includes("skill"))
            .reduce((acc, cur) => [...acc, cur[1]], []);
    };

    const progressBars = filterSkills().map((skill, key) => {
        const [title, level] = skill.split(",");
        return <ProgressBar title={title} level={level} key={key} />;
    });

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <PageTitle>{heading1}</PageTitle>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Text>{parse(text1)}</Text>
            </Grid>
            <Grid
                item
                xs={12}
                sm={6}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {progressBars}
            </Grid>
            <Grid item xs={12}>
                <Grid
                    container
                    sx={{
                        flexDirection: { xs: "column-reverse", sm: "row" },
                    }}
                >
                    {" "}
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        sx={{ display: "grid", placeContent: "center" }}
                    >
                        <IconCarousel {...rest} />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <PageTitle2>{heading2}</PageTitle2>
                        <Text>{parse(text2)}</Text>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default About;
