import moment from "moment";
import { userDefaultState } from "../store/defaults";

// Reducer to change user states in Redux store
const manageUserState = (state = userDefaultState, action) => {
    const { type, payload } = action;
    switch (type) {
        case "LOGIN_USER_SUCCESS":
            return {
                ...state,
                isLoggedIn: true,
                loginTimestamp: moment().valueOf(),
                profile: payload,
                error: null,
            };

        case "LOGIN_USER_FAILURE":
            return {
                ...state,
                error: payload,
            };

        case "LOGOUT_USER":
            return {
                ...state,
                isLoggedIn: false,
                loginTimestamp: null,
                profile: null,
            };

        case "SET_LANGUAGE":
            if (payload === "de") {
                return {
                    ...state,
                    language: "de-DE",
                };
            } else {
                return {
                    ...state,
                    language: "en-US",
                };
            }

        case "SET_COLOR_MODE":
            return {
                ...state,
                colorMode: payload,
            };

        case "ACCEPTED_COOKIES":
            return {
                ...state,
                acceptedCookies: true,
            };

        case "SET_ROLES":
            return {
                ...state,
                roles: payload.length ? payload : [],
            };

        default:
            return state;
    }
};

export default manageUserState;
