import React from "react";
import { Route, Redirect } from "react-router";
import { useSelector } from "react-redux";

import { getUserIsLoggedIn } from "../../selectors/user";

const PrivateRoute = ({ component: Component, ...rest }) => {
    // get current user state from store
    const isLoggedIn = useSelector((state) => getUserIsLoggedIn(state));

    return isLoggedIn ? (
        // navigate to requested component
        <Route {...rest}>
            {(props) => <Component {...props} key="component" />}
        </Route>
    ) : (
        // redirect user to login page
        <Redirect
            to={{
                pathname: "/login",
                state: { from: rest.location },
            }}
        />
    );
};

export default PrivateRoute;
