import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import validator from "validator";
import { toast } from "react-toastify";

import { Box, Grid, Link, CircularProgress } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import LockResetIcon from "@mui/icons-material/LockReset";
import DeleteIcon from "@mui/icons-material/Delete";
// import { makeStyles } from "@mui/styles";

import Form from "../../mui/controls/Form";
import Controls from "../../mui/controls/Controls";
import { useForm } from "../../hooks/useForm";

import { authAPI } from "../../configs/axios-wordpress";
import { startLoginUser } from "../../thunks/user";
import { loginUserFailure } from "../../actions/user";
import { fetchApiData } from "../../services/fetchApiData";

// Customized styles
// const useStyles = makeStyles((theme) => ({
//     root: {
//         marginTop: theme.spacing(3),
//         padding: theme.spacing(3),
//         maxWidth: "400px",
//     },
// }));

/*
 * Login form
 */
export const LoginForm = ({ referer, handleResetPassword }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    // Initial form control values
    const initValues = {
        email: "",
        password: "",
        remember: false,
    };

    // Custom hook for managing forms
    const { values, onChange, errors, setErrors, submitting, setSubmitting } =
        useForm({
            ...initValues,
        });

    // Toast notifications
    const notifySuccess = (name) =>
        toast.success(`${t("forms.welcome_back")}, ${name}!`);
    const notifyFailure = () => toast.error(t("forms.login_failed"));

    // Validate each form control, return true if no errors
    const validate = () => {
        const form = {}; // temp object to store error messages
        // Check multiple conditions and set appropriate error message
        switch (true) {
            case values.email.length === 0:
                form["email"] = t("forms.enter_email_address");
                break;
            case !validator.isEmail(values.email):
                form["email"] = t("forms.no_valid_email");
                break;
            default:
                form["email"] = "";
                break;
        }
        form["password"] =
            values.password.length === 0 ? t("forms.your_password") : "";
        // Check if all values in errors Object are empty
        const result = Object.values(form).every((val) => val === "");
        if (result) {
            setErrors(null); // delete previous errors, if any
            return true; // form data valid
        } else {
            setErrors({ ...form }); // set error message per form control
            return false; // form data invalid
        }
    };

    // Handle form submit
    const handleSubmit = async (e) => {
        e.preventDefault(); // prevent page re-load
        if (validate()) {
            try {
                // Show CircularProgress icon
                setSubmitting(true);
                // Get user credentials from form controls
                const { email, password } = values;
                // Call WP auth API and verify email + password
                const res = await authAPI.post("/token", {
                    username: email,
                    password: password,
                });
                const userProfile = res.data.data;
                // Set cookie and fetch private content from WP API
                dispatch(startLoginUser(userProfile));
                // Redirect user, defaults to /home
                history.push(referer || "/home");
                // Show welcome toast
                notifySuccess(userProfile.firstName);
            } catch (error) {
                // Authentication failure case
                dispatch(
                    loginUserFailure("User provided wrong login credentials!")
                );
                // Hide CircularProgress icon
                setSubmitting(false);
                // Show error toast
                notifyFailure();
            }
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Controls.Input
                name="email"
                margin="normal"
                required
                fullWidth
                label={t("forms.email")}
                autoComplete="email"
                autoFocus
                onFocus={() => setErrors({})}
                onChange={onChange}
                value={values.email}
                error={!!errors?.email}
                helperText={errors && errors.email}
            />
            <Controls.Input
                name="password"
                margin="normal"
                required
                fullWidth
                label={t("forms.password")}
                type="password"
                autoComplete="current-password"
                sx={{ mb: 2 }}
                onFocus={() => setErrors({})}
                onChange={onChange}
                value={values.password}
                error={!!errors?.password}
                helperText={errors && errors.password}
            />
            <Controls.Checkbox
                name="remember"
                label={t("forms.remember_me")}
                onChange={onChange}
                value={values.remember}
            />
            <Controls.Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                startIcon={
                    submitting ? (
                        <CircularProgress color="secondary" size={20} />
                    ) : (
                        <LoginIcon />
                    )
                }
            >
                {t("forms.login")}
            </Controls.Button>
            <Grid container>
                <Grid item xs align="right">
                    <Link
                        href="#"
                        onClick={handleResetPassword}
                        color="secondary"
                        underline="none"
                        variant="body2"
                    >
                        {t("forms.forgot_password")}
                    </Link>
                </Grid>
            </Grid>
        </Form>
    );
};

/*
 * Forgot Password form
 */
export const ForgotPasswordForm = ({ handleLogin }) => {
    const history = useHistory();
    const { t } = useTranslation();

    // Array of registered user emails in WP
    const [registeredEmails, setRegisteredEmails] = useState([]);

    useEffect(() => {
        // Get array of registered emails from WP API
        const fetchEmails = async () => {
            const res = await fetchApiData(["users/emails"]);
            const emails = res[0].data;
            setRegisteredEmails(emails);
        };
        fetchEmails();
    }, []);

    // Initial form control values
    const initValues = {
        email: "",
    };

    // Custom hook for managing forms
    const { values, onChange, errors, setErrors, submitting, setSubmitting } =
        useForm({
            ...initValues,
        });

    // Validate each form control, return true if no errors
    const validate = () => {
        const form = {}; // temp object to store error messages
        // Check multiple conditions and set appropriate error message
        switch (true) {
            case values.email.length === 0:
                form["email"] = t("forms.enter_email_address");
                break;
            case !validator.isEmail(values.email):
                form["email"] = t("forms.no_valid_email");
                break;
            case !registeredEmails.includes(values.email):
                form["email"] = t("forms.email_not_registered");
                break;
            default:
                form["email"] = "";
                break;
        }
        // Check if all values in errors Object are empty
        const result = Object.values(form).every((val) => val === "");
        if (result) {
            setErrors(null); // delete previous errors, if any
            return true; // form data valid
        } else {
            setErrors({ ...form }); // set error message per form control
            return false; // form data invalid
        }
    };

    // Handle form submit
    const handleSubmit = async (e) => {
        e.preventDefault(); // prevent page re-load
        if (validate()) {
            try {
                // Show CircularProgress icon
                setSubmitting(true);
                // Get inserted email from form control
                const { email } = values;
                // Call WP API and send email with reset link
                // TODO: trigger reset email API
                console.log({ email });
                // Hide CircularProgress icon
                setSubmitting(false);
                // Show success toas message
                notifySuccess();
                // Redirect user, defaults to /home
                history.push("/home");
            } catch (error) {
                // Hide loading spinner
                setSubmitting(false);
            }
        }
    };

    // Toast notifications
    const notifySuccess = () =>
        toast.success(t("forms.password_reset_email_sent"));

    return (
        <Form onSubmit={handleSubmit}>
            <Controls.Input
                margin="normal"
                required
                fullWidth
                label={t("forms.email")}
                name="email"
                autoComplete="email"
                autoFocus
                onFocus={() => setErrors({})}
                onChange={onChange}
                value={values.email}
                error={!!errors?.email}
                helperText={errors && errors.email}
            />
            <Box
                sx={{
                    mt: 4,
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                <Controls.Button
                    onClick={handleLogin}
                    variant="outlined"
                    sx={{ mt: 3 }}
                    startIcon={<DeleteIcon />}
                >
                    {t("forms.cancel")}
                </Controls.Button>
                <Controls.Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, ml: 1 }}
                    startIcon={
                        submitting ? (
                            <CircularProgress color="secondary" size={20} />
                        ) : (
                            <LockResetIcon />
                        )
                    }
                >
                    {t("forms.send_reset_email")}
                </Controls.Button>
            </Box>
        </Form>
    );
};
