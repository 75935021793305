import { styled } from "@mui/material/styles";
import { Avatar } from "@mui/material";

// Homepage Logo Buttom
export const LogoAvatar = styled((props) => (
    <Avatar
        alt="Steffen Fidelius"
        src="/images/avatar-steffen-2.jpg"
        sx={{ width: 56, height: 56 }}
        // variant="square"
    />
))(({ theme }) => ({
    margin: theme.spacing(1),
}));
