import React from "react";
import { Link } from "react-router-dom";

import styles from "./ActionButton.module.scss";

// Provides four different styles of buttons (type1 - type4)
const ActionButton = (props) => {
    const { type = "type1", href = "#", label = "Label", ...rest } = props;

    return (
        <div className={styles.container}>
            <Link
                to={href}
                className={`${styles.button} ${styles[type]}`}
                {...rest}
            >
                {label}
            </Link>
        </div>
    );
};

export default ActionButton;
