import React from "react";
import { Grid } from "@mui/material";
import parse from "html-react-parser"; // Rendering the HTML string, will be vulnerable to cross-site scripting attacks (XSS).

import { usePage } from "../../hooks/usePage";
import { PageTitle } from "../../mui/typography/Typography";

const LegalDetails = () => {
    const [page] = usePage("impressum");

    return (
        <Grid container>
            <Grid item xs={12}>
                <PageTitle>{page.title}</PageTitle>
            </Grid>
            <Grid item xs={12}>
                {parse(page.content)}
            </Grid>
        </Grid>
    );
};

export default LegalDetails;
