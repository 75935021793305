import React, { forwardRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser"; // Rendering the HTML string, will be vulnerable to cross-site scripting attacks (XSS).

import {
    Box,
    Dialog,
    Avatar,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
} from "@mui/material";
import CookieIcon from "@mui/icons-material/Cookie";

import { constants } from "../../configs/constants";
import Controls from "../../mui/controls/Controls";
import { getPageBySection } from "../../selectors/wpContent";
import { setAcceptedCookies } from "../../actions/user";
import { getUserAcceptedCookies } from "../../selectors/user";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Banner = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // Check if cookie dialog was already shown
    const acceptedCookies = useSelector((state) =>
        getUserAcceptedCookies(state)
    );

    // Get banner content from redux store
    const page = useSelector((state) => getPageBySection(state, "Cookies"));
    const { title, content } = page;

    // Hide cookie dialog for session
    const handleClose = () => {
        dispatch(setAcceptedCookies(true));
    };

    // Auto-close banner after a few seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(setAcceptedCookies(true));
        }, constants.COOKIE_BANNER_TIMEOUT);
        return () => clearTimeout(timer);
    }, [dispatch]);

    return (
        <div>
            <Dialog
                open={!acceptedCookies}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <DialogTitle component="div">{title}</DialogTitle>
                    <Avatar
                        sx={{
                            bgcolor: "secondary.main",
                            marginRight: (t) => t.spacing(3),
                        }}
                    >
                        <CookieIcon />
                    </Avatar>
                </Box>
                <DialogContent dividers={true}>
                    <DialogContentText component="div">
                        {parse(content)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Controls.Button
                        onClick={handleClose}
                        fullWidth
                        variant="contained"
                        style={{ margin: "1rem" }}
                    >
                        {t("forms.got_it")}
                    </Controls.Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Banner;
