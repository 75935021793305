import React, { useState } from "react";
import validator from "validator";

// import { makeStyles } from "@mui/styles";
import { Grid, Divider } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SaveIcon from "@mui/icons-material/Save";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";

import Form from "../../mui/controls/Form";
import { useForm } from "../../hooks/useForm";
import Controls from "../../mui/controls/Controls";

import { changePassword as changePasswordService } from "../../services/users";

// import { useTranslation } from "react-i18next";

// Override/append MUI styles
// const useStyles = makeStyles((theme) => ({
//     btn: {
//         marginLeft: theme.spacing(1),
//         marginTop: theme.spacing(2),
//     },
// }));

/*
 * ProfileForm
 */
const ProfileForm = ({ profile, roles }) => {
    // const { t } = useTranslation();
    // const classes = useStyles();
    const { values, onChange, setInitValues, errors, setErrors } = useForm({
        ...profile,
    });

    const [editMode, setEditMode] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            const data = new FormData(e.currentTarget);
            console.log({
                firstName: data.get("firstName"),
                lastName: data.get("lastName"),
                email: data.get("email"),
            });
        }
    };

    // Validate each form control and return true if no errors found
    const validate = () => {
        const form = {};
        form["firstName"] =
            values.firstName.length === 0 ? "Must not be empty" : "";
        form["lastName"] =
            values.lastName.length === 0 ? "Must not be empty" : "";
        form["email"] = validator.isEmail(values.email)
            ? ""
            : "No valid email format";
        // Check if every value in errors Object is empty
        const result = Object.values(form).every((val) => val === "");
        if (result) {
            setErrors(null); // delete any errors if any
            return true; // form data valid
        } else {
            setErrors({ ...form }); // set error message per form control
            return false; // form data invalid
        }
    };

    const handleEditMode = () => setEditMode(!editMode);

    const handleCancel = () => {
        setInitValues(); // reset form values
        setErrors(null); // delete any existing errors
        setEditMode(false);
    };

    return (
        <>
            <Divider />
            <Form onSubmit={handleSubmit}>
                <Grid container spacing={2} sx={{ p: 3 }}>
                    <Grid item xs={12} sm={6}>
                        <Controls.Input
                            name="firstName"
                            id="firstName"
                            label="First Name"
                            value={values.firstName}
                            onChange={onChange}
                            required
                            disabled={!editMode}
                            error={!!errors?.firstName}
                            helperText={errors && errors.firstName}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controls.Input
                            name="lastName"
                            id="lastName"
                            label="Last Name"
                            value={values.lastName}
                            onChange={onChange}
                            required
                            disabled={!editMode}
                            error={!!errors?.lastName}
                            helperText={errors && errors.lastName}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controls.Input
                            name="email"
                            id="email"
                            label="Email"
                            value={values.email}
                            onChange={onChange}
                            required
                            disabled={!editMode}
                            error={!!errors?.email}
                            helperText={errors && errors.email}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", justifyContent: "center" }}
                    >
                        {editMode ? (
                            <>
                                <Controls.Button
                                    onClick={handleCancel}
                                    variant="outlined"
                                    startIcon={<DeleteIcon />}
                                >
                                    Cancel
                                </Controls.Button>
                                <Controls.Button
                                    type="submit"
                                    variant="contained"
                                    startIcon={<SaveIcon />}
                                >
                                    Save Profile Changes
                                </Controls.Button>
                            </>
                        ) : (
                            <Controls.Button
                                onClick={handleEditMode}
                                startIcon={<ModeEditIcon />}
                            >
                                Edit Profile
                            </Controls.Button>
                        )}
                    </Grid>
                </Grid>
            </Form>
            <ChangePasswordForm />
        </>
    );
};

/*
 * ChangePasswordForm
 */
const ChangePasswordForm = () => {
    const initValues = {
        password1: "",
        password2: "",
    };

    const { values, onChange, setInitValues, errors, setErrors } =
        useForm(initValues);

    // Show/Hide change password form
    const [changePassword, setChangePassword] = useState(false);

    // Validate each form control and return true if no errors found
    const validate = (p1, p2) => {
        const form = {};
        form["password1"] = validator.isStrongPassword(p1, {
            minLength: 8,
            minLowercase: 0,
            minUppercase: 0,
            minNumbers: 0,
            minSymbols: 0,
        })
            ? ""
            : "At least 8 characters";
        form["password2"] = validator.isStrongPassword(p2, {
            minLength: 8,
            minLowercase: 0,
            minUppercase: 0,
            minNumbers: 0,
            minSymbols: 0,
        })
            ? ""
            : "At least 8 characters";
        form["password2"] = p1 === p2 ? "" : "Must be equal";

        // Check if every value in errors Object is empty
        const result = Object.values(form).every((val) => val === "");

        if (result) {
            setErrors(null); // delete any errors if any
            return true; // form data valid
        } else {
            setErrors({ ...form }); // set error message per form control
            return false; // form data invalid
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const p1 = data.get("password1");
        const p2 = data.get("password2");
        if (validate(p1, p2)) {
            try {
                const res = await changePasswordService(p1);
                console.log(res);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleCancel = () => {
        setInitValues();
        setErrors(null); // delete any existing errors
        setChangePassword(!changePassword);
    };

    const handleChangePassword = () => setChangePassword(!changePassword);

    return (
        <>
            <Divider />
            <Form onSubmit={handleSubmit}>
                <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    sx={{ p: 3 }}
                >
                    {changePassword && (
                        <>
                            <Grid item xs={12} sm={6}>
                                <Controls.Input
                                    name="password1"
                                    id="password1"
                                    label="New Password"
                                    value={values.password1}
                                    onChange={onChange}
                                    required
                                    type="password"
                                    error={!!errors?.password1}
                                    helperText={errors && errors.password1}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controls.Input
                                    name="password2"
                                    id="password2"
                                    label="Repeat"
                                    value={values.password2}
                                    onChange={onChange}
                                    required
                                    type="password"
                                    error={!!errors?.password2}
                                    helperText={errors && errors.password2}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item>
                        {changePassword ? (
                            <>
                                <Controls.Button
                                    onClick={handleCancel}
                                    variant="outlined"
                                    startIcon={<DeleteIcon />}
                                >
                                    Cancel
                                </Controls.Button>
                                <Controls.Button
                                    type="submit"
                                    variant="contained"
                                    startIcon={<SaveIcon />}
                                >
                                    Save new Password
                                </Controls.Button>
                            </>
                        ) : (
                            <Controls.Button
                                onClick={handleChangePassword}
                                startIcon={<ChangeCircleIcon />}
                            >
                                Change Password
                            </Controls.Button>
                        )}
                    </Grid>
                </Grid>
            </Form>
        </>
    );
};

export default ProfileForm;
