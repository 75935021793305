import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getRouteConfig } from "../selectors/wpContent";

import { Tabs, Tab, Box } from "@mui/material";

export const useTabs = (props) => {
    // Get routes config
    const routes = useSelector((state) => getRouteConfig(state));
    // Find the F&F object
    const dropdown = routes.find((route) => route.type === "menu");
    // Get F&F menu items
    const ffMenuItems = dropdown.items;
    // Get current path/route from URL
    const currentPath = props.match.path.split("/")[2] || 0;
    // Get history object for route changes
    const history = props.history;

    // Create array with entries for all paths that exist under "/ff/"
    const ffPaths = ffMenuItems.map((item, key) => [
        key,
        item.to.split("/")[2],
    ]);

    // Create object with index as key and path as value plus an inverse version
    const indexToPath = Object.fromEntries(ffPaths);
    const ffPathsInverse = ffPaths.map(([k, v]) => [v, k]);

    // Create object with path as key and index as value
    const pathToIndex = Object.fromEntries(ffPathsInverse);

    // Store active Tab
    const [currentIndex, setCurrentIndex] = useState(pathToIndex[currentPath]);

    // Handle tab click
    const handleChange = (event, newIndex) => {
        setCurrentIndex(newIndex);
        history.push(`/ff/${indexToPath[newIndex]}`);
    };

    const tabHeader = (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={currentIndex}
                    onChange={handleChange}
                    aria-label="family friends navigation tabs"
                    centered
                >
                    {ffMenuItems.map((item, key) => (
                        <Tab label={item.label} key={key} />
                    ))}
                </Tabs>
            </Box>
        </Box>
    );

    return [tabHeader];
};
