import React from "react";

import MuiButton from "@mui/material/Button";

// Standard Button
const Button = (props) => {
    const {
        children,
        color = "secondary",
        size = "small",
        variant = "text",
        ...rest
    } = props;

    return (
        <MuiButton {...rest} color={color} size={size} variant={variant}>
            {children}
        </MuiButton>
    );
};

export default Button;
