import React from "react";

import {
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Link,
} from "@mui/material";
import ItemDownload from "./ItemDownload";

const ItemList = ({ items, handleItemClick }) => {
    if (!items.length) return null;
    return (
        <ImageList variant="masonry" cols={4} gap={8}>
            {items.map((item) => (
                <ImageListItem key={item.title}>
                    <img
                        src={`${item.medium}?w=248&fit=crop&auto=format`}
                        srcSet={`${item.medium}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        alt={item.title}
                        loading="lazy"
                        onClick={() => handleItemClick(item)}
                    />
                    <ImageListItemBar
                        subtitle={item.title}
                        actionIcon={<ItemDownload item={item} />}
                    />
                </ImageListItem>
            ))}
        </ImageList>
    );
};

export default ItemList;
